import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { NotificationsService } from 'angular2-notifications';
import { CookieService } from 'ngx-cookie-service';

import {
  DefaultLangChangeEvent,
  LangChangeEvent,
  TranslateService,
  TranslationChangeEvent
} from '@ngx-translate/core';
import { CODINGCONSTANTS } from 'src/app/common/coding-constants';
import { CONSTANTS } from 'src/app/common/constants';
import { Languages } from 'src/app/common/languages';
import { ExpiredPasswordModal } from 'src/app/components/modal/expired-password-modal/expired-password-modal.component';
import { AuthService } from 'src/app/services/auth.service';

// Jquery
declare var $: any;

@Component({
  selector: 'login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild(ExpiredPasswordModal)
  expiredModal!: ExpiredPasswordModal;
  username: string = '';
  password: string = '';
  emailId: string = '';
  otp: string = '';
  isFormValid: boolean = false;
  isWebView: boolean = false;
  oAuth2MobileSignedIn: boolean = false;
  oAuth2MobileSignedOut: boolean = false;
  isLoading: boolean = false;
  sephoraTitle: string = CONSTANTS.TITLE;
  welcomeTitle: string = CONSTANTS.WELCOME_TITLE;
  loginBaApp: string = CONSTANTS.LOGIN_BA_APP;
  loggingIn: string = CONSTANTS.LOGGING_IN + CODINGCONSTANTS.DOTS;
  loggingOut: string = CONSTANTS.LOGGING_OUT + CODINGCONSTANTS.DOTS;
  loginOkta: string = CONSTANTS.LOGIN_OKTA;
  generateOTP: string = CONSTANTS.GENERATE_OTP;
  resendOTP: string = CONSTANTS.RESEND_OTP;
  confirmLbl: string = CONSTANTS.CONFIRM_LBL;
  employeeId: string = CONSTANTS.EMPLOYEE_ID;
  employeeIdEmpty: string = CONSTANTS.EMPLOYEE_ID_EMPTY;
  employeeIdInvalid: string = CONSTANTS.EMPLOYEE_ID_INVALID;
  passwordText: string = CONSTANTS.PASSWORD;
  passwordEmpty: string = CONSTANTS.PASSWORD_EMPTY;
  passwordInvalid: string = CONSTANTS.PASSWORD_INVALID;
  serverError: string = CONSTANTS.SERVER_ERROR;
  serverErrorConnectAdmin: string = CONSTANTS.SERVER_ERROR_CONNECT_ADMIN;
  login: string = CONSTANTS.LOGIN;
  forgotPassword: string = CONSTANTS.FORGOT_PASSWORD;
  or: string = CONSTANTS.OR;
  notASephoraUserText: string = CONSTANTS.NOT_A_SEPHORA_USER;
  loginHere: string = CONSTANTS.LOGIN_HERE;
  selectedLanguage = "en-SG";
  siteLanguage = 'English';
  localLanguages = new Languages().language;
  languageList = this.localLanguages;
  isOktaLoginOnly = true;
  isOtpLogin = false;
  isOtpSending = false;
  source = 'baWebApp'; 
  showIframe = false;
  showBaLogin = false;

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private cookieService: CookieService,
    private translate: TranslateService) {
      this.translate.onLangChange
          .subscribe((event: LangChangeEvent) => {
          localStorage.setItem("selectedLanguage", event.lang);
      });

      this.translate.onTranslationChange
          .subscribe((event: TranslationChangeEvent) => {
      });

      this.translate.onDefaultLangChange
          .subscribe((event: DefaultLangChangeEvent) => {
      });
    }

  ngOnInit() {
    localStorage.clear();
    this.title.setTitle('Sephora BA App User Management');
    this.isOtpLogin = false;
    this.checkWebView();
    this.route.queryParams.subscribe(params => {
      this.oAuth2MobileSignedIn = (params['oAuth2MobileSignedIn'] && params['oAuth2MobileSignedIn'] == 'true') ? true : false;
    });
    if (this.oAuth2MobileSignedIn){
      this.oAuth2MobileLogin();
    }
    this.route.queryParams.subscribe(params => {
      this.oAuth2MobileSignedOut = (params['oAuth2MobileSignedOut'] && params['oAuth2MobileSignedOut'] == 'true') ? true : false;
    });
    if (this.oAuth2MobileSignedOut){
      this.oAuth2MobileLogout();
    }
    this.route.queryParams.subscribe(params => {
      this.isOktaLoginOnly = (params['oktaError'] && params['oktaError'] == 'true') ? false : true;
    });
    this.route.queryParams.subscribe(params => {
      this.showBaLogin = (params['baAppLogin'] && params['baAppLogin'] == 'true') ? true : false;
    });
    localStorage.setItem("selectedLanguage", this.selectedLanguage);
 }

  checkWebView() {
    const userAgent = (window as any).navigator.userAgent.toLowerCase(),
    safari = /safari|chrome/.test( userAgent ),
    iOS = /iphone|ipod|ipad/.test( userAgent );
    if (iOS && !safari) {
      this.isWebView = true;
    }
    // To simulate mobile mode in Desktop browser
    //this.isWebView = true
 }

  oAuth2MobileLogin() {
    this.isLoading = true;
    this.authService.oAuth2MobileLogin().subscribe(
      res => {
        if (res) {
          (window as any).webkit.messageHandlers.jsMessageHandler.postMessage(res);
        }
        this.isLoading = false;
      },
      err => {
        (window as any).webkit.messageHandlers.jsMessageHandler.postMessage(err);
        if (err.status === 0) {
          this.isLoading = false;
          window.location.href = window.location.origin + "/oauth2/authorization/okta?device=mobile";
        } else {
          this.notificationsService.error(
            "Error!",
            err.status === 500 ? err.error : err.status === 403 ? err.error.message : err.statusText,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
            }
          );
          this.isLoading = false;
          this.router.navigate(["/login"]);
        }
      }
    );
  }

  oAuth2MobileLogout(){
    this.isLoading = true;
    this.authService.logoutOAuth2().subscribe(
      res => {
        let logoutUrl = this.authService.oktaLogoutUrl + '?id_token_hint=' + this.authService.oktaIdToken + '&post_logout_redirect_uri=' + window.location.origin;
        localStorage.clear();
        this.cookieService.delete('remember');
        this.isLoading = false;
        if (this.authService && this.authService.oktaLogoutUrl && this.authService.oktaIdToken && window && window.location) {
          window.location.href = logoutUrl;
        } else {
          this.router.navigate(["/login"]);
        }
      },
      err => {
        this.notificationsService.error(
          "Error!",
          err.status === 500? err.error : err.statusText,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
          }
        );
        this.isLoading = false;
        this.router.navigate(["/login"]);
      }
    );
  }

  authenticate() {
    this.isFormValid = true;
    this.clearError();

    if(!window.navigator.onLine){
      $('#password ~ .text-error')
            .eq(3)
            .css('opacity', 1);
      return;
   }

    this.validateRequired();

    // Stop if form is invalid
    if (!this.isFormValid) return;
    this.isLoading = true;
    this.disableLogin();
    this.authService.login(this.username, this.password).subscribe(
      res => {
        // If user is successfully authenticated
        if (res) {
          var expired = this.authService.expiredWebPass;
          if (expired == 1){
            this.expiredModal.show(
              CONSTANTS.PASSWORD_EXPIRY,
              CONSTANTS.CLICK_MSG,
              () => {
                this.expiredModal.close();
                this.router.navigate(['change-pass']);
             }
            );
         }else{
            this.router.navigate([this.authService.redirectUrl]);
         }
       }
        this.enableLogin();
        this.isLoading = false;
     },
      err => {
        if(err.status == 401){
          if ('Invalid username!' === err.error.message) {
            $('#username ~ .text-error')
              .eq(1)
              .css('opacity', 1);
         } else {
            // Show password error text
            $('#password ~ .text-error')
              .eq(1)
              .css('opacity', 1);
         }
        } else if(err.status == 403){
          $('#password ~ .text-error')
          .eq(3)
          .css('opacity', 1)
          .html(err.error.message);
        } else if (err.status == 0){
          $('#password ~ .text-error')
          .eq(2)
          .css('opacity', 1);
        } else {
          $('#password ~ .text-error')
          .eq(3)
          .css('opacity', 1);
        }

        this.enableLogin();
        this.isLoading = false;
        Bugsnag.notify(err);
     }
    );
 }

 oktaAuthenticate() {
  window.location.href = "/oauth2/authorization/okta";
 }

 loginWithBAApp() {
  this.showIframe = true;
 console.log('Login with BA App');
 this.listenForAuthHandler();
}


listenForAuthHandler() {
  console.log("in auth handler")
 window.addEventListener('message', (event) => {
   if (event.origin !== window.location.origin) {
     return;
   }
   if (event.data && event.data.type === "authHandler") {
     this.handleAuthHandler(event.data.data);
   }
 });
}

handleAuthHandler(payload: any) {
  console.log(payload);
  if (payload && payload.status === 'true') {
    console.log(payload.ba)
    this.createSession(payload.ba);
    console.log('Session created for BA:', payload.ba.email);
  } else if(payload && payload.status === 'false') {
    console.log("authentication failed")
    // this.showErrorPopup("Authentication failed"); 
  //   setTimeout(()=>{                         
  //     window.parent.location = '/#/login';
  // }, 2000);
    
  } else {
//  this.showWarnPopup("Retrying to fetch login info from backend");
    console.error('Retrying to fetch login info from backend');
  }
}

showWarnPopup(message: string) {
  this.notificationsService.warn(
    CONSTANTS.INFO,
    message,
    {
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    }
  );
}

showErrorPopup(message: string) {
  this.notificationsService.error(
    CONSTANTS.ERROR,
    message,
    {
      timeOut: 5000,
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
   }
  );
}

createSession(baData: any) {
  if (baData && baData.email) {
    this.authService.loginWithQr(baData.loginId,baData.email).subscribe(
      res => {
        // If user is successfully authenticated
        if (res) {
          var expired = this.authService.expiredWebPass;
          if (expired == 1){
            this.expiredModal.show(
              CONSTANTS.PASSWORD_EXPIRY,
              CONSTANTS.CLICK_MSG,
              () => {
                this.expiredModal.close();
                this.router.navigate(['change-pass']);
             }
            );
         }else{
            this.router.navigate([this.authService.redirectUrl]);
         }
       }
        this.enableLogin();
        this.isLoading = false;
     },
      err => {
        if(err.status == 401){
          if ('Invalid username!' === err.error.message) {
            $('#username ~ .text-error')
              .eq(1)
              .css('opacity', 1);
         } 
         else {
            // Show password error text
            $('#password ~ .text-error')
              .eq(1)
              .css('opacity', 1);
         }
       }
       else if(err.status === 403) {
        console.log(err.error);
            this.showErrorPopup(err.error.message);
       } 
       else if (err.status == 0){
          $('#password ~ .text-error')
          .eq(2)
          .css('opacity', 1);
       } else {
          $('#password ~ .text-error')
          .eq(3)
          .css('opacity', 1);
       }

        this.enableLogin();
        this.isLoading = false;
        Bugsnag.notify(err);
     });
  }
}

  handleForgotPassword() {
    this.router.navigate(['/recover']);
 }

  otpLogin() {
    this.isOtpLogin = true;
    this.isOktaLoginOnly = false;
  }

  validateEmailId(event: any) {
    if ((event.target as HTMLInputElement).value) {
      const value = (event.target as HTMLInputElement).value;
      $(event.target).siblings('.text-error').eq(0).css('opacity', 0);
      this._validateEmail(value);
    }
  }

  private _validateEmail(value: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(value) == false) {
      $('#emailId ~ .text-error').eq(0).css('opacity', 1).html(CONSTANTS.INVALID_EMAIL);
      return false;
    } else {
      return true;
    }
  }

  generateOtp() {
    if (!this.isOtpSending) {
      $('#emailId ~ .text-error').eq(0).css('opacity', 0);
      if (this._validateEmail(this.emailId)) {
        if(!window.navigator.onLine){
          $('#emailId ~ .text-error')
                .eq(3)
                .css('opacity', 1);
          return;
        }

        this.isLoading = true;
        this.authService.otpLogin(this.emailId, '').subscribe(
          res => {
            if (res) {
              if (res.status == 201) {
                let timeleft = 59;
                let resendOTPTimer = setInterval(() => {
                  if(timeleft <= 0){
                    clearInterval(resendOTPTimer);
                    $('#resendOTPTimeLeft').html('');
                    $('#resendOTP').css('cursor', 'pointer');
                    $('#resendOTP').hover().css('text-decoration', 'underline');
                    this.isOtpSending = false;
                  } else {
                    $('#resendOTPTimeLeft').html(`in ${timeleft}s`);
                    $('#resendOTP').css('cursor', 'default');
                    $('#resendOTP').hover().css('text-decoration', 'none');
                    this.isOtpSending = true;
                  }
                  timeleft -= 1;
                }, 1000);
  
                $('#otp-input').attr('hidden', false);
                $('#generate-otp-button').hide();
                $('#confirm-opt-button').attr('hidden', false);
              } else {
                $('#emailId ~ .text-error').eq(0).css('opacity', 1).html(res.body.message);
              }
            }
            this.isLoading = false;
          },
          err => {
            this.isLoading = false;
            $('#emailId ~ .text-error').eq(0).css('opacity', 1).html(err.error.message);
            Bugsnag.notify(err);
          }
        );
      }
    }
  }

  confirmOtp() {
    if (this.otp && this.otp.length == 6 && !isNaN(Number(this.otp))) {
      this.isLoading = true;
      this.authService.otpLogin(this.emailId, this.otp).subscribe(
        res => {
          if (res) {
            this.router.navigate([this.authService.redirectUrl]);
          }
          this.isLoading = false;
        },
        err => {
          this.isLoading = false;
          $('#otp ~ .text-error').eq(0).css('opacity', 1).html(err.error.message);
          Bugsnag.notify(err);
        }
      );
    } else {
      this.isLoading = false;
      $('#otp ~ .text-error').eq(0).css('opacity', 1).html('Invalid OTP.');
    }
  }

  clearErrorIfValid(e: any) {
    if (e.target.value) {
      $(e.target)
        .siblings('.text-error')
        .eq(0)
        .css('opacity', 0);
   }
 }

  clearError() {
    $('.text-error').css('opacity', 0);
 }

  disableLogin() {
    $('.login-button').addClass('disabled');
    $('.login-button').attr('disabled', true);
 }

  enableLogin() {
    $('.login-button').removeClass('disabled');
    $('.login-button').attr('disabled', false);
 }

  validateRequired() {
    if (!this.username || !this.username.trim()) {
      $('#username ~ .text-error')
        .eq(0)
        .css('opacity', 1);
      this.isFormValid = false;
   }
    if (!this.password || !this.password.trim()) {
      $('#password ~ .text-error')
        .eq(0)
        .css('opacity', 1);
      this.isFormValid = false;
   }
 }

  handleKeyDown(event: any) {
    // If 'Enter'
    if (event.keyCode == 13 && this.username && this.password) {
      this.authenticate();
   }
 }
 changeLanguage(){
  alert($('#v div li a').attr('id'));
  this.selectedLanguage = $('#selectedLanguage div li a').attr('id');
  localStorage.setItem("selectedLanguage", this.selectedLanguage);
  this.translateLanguageTo(this.selectedLanguage);
  }
  //Switch language
  translateLanguageTo(lang: string) {
      $('#languageOpted').text(lang);
      this.selectedLanguage = lang;
      this.translate.use(lang);
  }

changeSiteLanguage(localeCode: string): void {
  this.selectedLanguage = localeCode;
  localStorage.setItem("selectedLanguage", this.selectedLanguage);
  const selectedLanguage = this.languageList
    .find((language) => language.code === localeCode)
    ?.label.toString();
  if (selectedLanguage) {
    this.siteLanguage = selectedLanguage;
    this.translate.use(localeCode);
  }
}

}