import { Component, OnInit } from "@angular/core";
import { DomSanitizer} from '@angular/platform-browser';
import { request } from "http";
import { ActivatedRoute } from '@angular/router';
import { DomoService } from 'src/app/services/domo.service';

declare var $: any;

@Component({
  selector: 'domo-component',
  templateUrl: './domo.component.html',
  styleUrls: ['./domo.component.css']
})
export class DomoComponent implements OnInit {
    constructor(
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private domoService: DomoService
        ) {}
    domoUrl: string = '';
    authenticated: boolean = false;
    token: string = '';
    ngOnInit() {
      this.domoUrl = "https://sephora-sg.domo.com/embed/card/private/lx0wr";
      this.sendFilters();
    }


  sanitize() {
    alert(this.domoUrl)
    return this.sanitizer.bypassSecurityTrustUrl(this.domoUrl);
  }

  sendFilters() {
    this.route.queryParams.subscribe(params => {
        this.token = params['token'];  // 'token' is the name of the query parameter
        console.log('Token:', this.token);
      });
  
    this.domoService.sendFilters(this.token).subscribe(
        res => {
          if (res) {
            console.log(res);
            // (window as any).webkit.messageHandlers.jsMessageHandler.postMessage(res);
          }
         this.authenticated = true;
        },
        err => {
            this.authenticated = false;
            alert('error calling domo');
        }
    );
  }


    
  }
