import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import Bugsnag from '@bugsnag/js';

import {CONSTANTS} from 'src/app/common/constants';
import {AuthService} from 'src/app/services/auth.service'
import {CODINGCONSTANTS} from 'src/app/common/coding-constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  userAgent = (window as any).navigator.userAgent.toLowerCase(); 
  safari = /safari|chrome/.test( this.userAgent ); 
  iOS = /iphone|ipod|ipad/.test( this.userAgent );
  isWebView = this.iOS && !this.safari;
  // To simulate mobile mode in Desktop browser
  //this.isWebView = true
  currentYear = new Date().getFullYear();
  sephoraRights: string = CONSTANTS.SPEHORA_RIGHTS;
  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    public translate: TranslateService
  ) {
    // Register translation languages
    translate.addLangs(['en', 'kr']);
    // Set default language
    translate.setDefaultLang('en-SG');
  }
  ngOnInit() {
    if (this.cookieService.get('remember') == 'true' && this.cookieService.get('token')) {
      this.authService.isLoggedIn = true;
      this.authService.token = this.cookieService.get('token');
      this.authService.id = localStorage.getItem('id') as string;
      this.authService.userId = localStorage.getItem('userId') as string;
      this.authService.firstName = localStorage.getItem('firstName') as string;
      this.authService.fullName = localStorage.getItem('fullName') as string;
      this.authService.isOAuth2Authenticate = JSON.parse(localStorage.getItem("isOAuth2Authenticate") as any);

      let authorities: any[] = [];
      try {
        authorities = [].concat(JSON.parse(localStorage.getItem('authorities') as any));
      } catch (e: any) {
          authorities = [{'authority' : ''}];
          Bugsnag.notify(e)
      }
      this.authService.authorities = authorities
      if (authorities.length && authorities[0]) {
        this.authService.isSystemAdmin = authorities.some(it => it.authority == CODINGCONSTANTS.SA_ROLE);
        this.authService.isRetailOps = authorities.some(it => it.authority == CODINGCONSTANTS.RO_ROLE);
        this.authService.isSM = authorities.some(it => it.authority == CODINGCONSTANTS.SM_ROLE);
        this.authService.isSMT = authorities.some(it => it.authority == CODINGCONSTANTS.SMT_ROLE);
        this.authService.isBM = authorities.some(it => it.authority == CODINGCONSTANTS.BM_ROLE);
        this.authService.isBR = authorities.some(it => it.authority == CODINGCONSTANTS.BR_ROLE);
      }

      this.authService.storeCode = localStorage.getItem('storeCode') as string;
      this.authService.storeName = localStorage.getItem('storeName') as string;
      this.authService.storeCountry = localStorage.getItem('storeCountry') as string;
      this.authService.location = localStorage.getItem('location') as string;
      this.authService.countries = [].concat(JSON.parse(localStorage.getItem('countries') as any));
   }
 }
  //Switch language
  translateLanguageTo(lang: string) {
    this.translate.use(lang);
  }
}
