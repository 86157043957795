<div class="user-wrapper">
  <div>
    <div class="table-title">{{storeManagement ?  ('STORE_MANAGEMENT' | translate) :  storeManagement}}</div>
  </div>
  <div *ngIf="isLoading || isSendingForm" class="loading-spinner">
    <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg" />
  </div>
  <div *ngIf="!isLoading" class="sephora-container" [class.opaque]="isSendingForm">
    <form class="form-horizontal">
      <!-- <div class="row">
        <div class="col-md-6">&nbsp;</div>
        <div class="col-md-4">&nbsp;</div>
        <div class="col-md-2">
          <button *ngIf="isViewMode"type="button" class="btn btn-dark" (click)="renderDomo()" >{{viewSales ? ('VIEW_SALES' | translate) : viewSales}}</button>
        </div>
        </div> -->
      <!--  -->
      <div class="form-group">
        <div class="row">
          <label for="storeCode" class="control-label col-md-2">{{storeCodeText ? ('STORE_CODE' | translate) : storeCodeText}}</label>
          <div class="col-md-6">
            <input NumberInputFormat [disabled]="isEditMode || isViewMode" type="text" id="storeCode" name="storeCode" class="form-control" maxlength="4" [(ngModel)]="storeForm.storeCode"
              (focusout)="clearErrorIfValidRequire($event)">
            <div class="text-error" for="storeCode"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="storeName" class="control-label col-md-2">{{storeNameText ? ('STORE_NAME' | translate) : storeNameText}}</label>
          <div class="col-md-6">
            <input type="text" id="storeName" name="storeName" [disabled]="isViewMode" class="form-control" maxlength="50" [(ngModel)]="storeForm.storeName" (focusout)="clearErrorIfValidRequire($event)">
            <div class="text-error" for="storeName"></div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label for="storeEmail" class="control-label col-md-2">{{storeEmailText ? ('STORE_EMAIL' | translate) : storeEmailText}}</label>
          <div class="col-md-6">
            <input type="text" id="storeEmail" name="storeEmail" [disabled]="isViewMode" class="form-control" maxlength="30" [(ngModel)]="storeForm.storeEmail" (focusout)="clearErrorIfValidRequire($event)">
            <div class="text-error" for="storeEmail"></div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label for="host" class="control-label col-md-2">{{host ? ('HOST' | translate) : host}}</label>
          <div class="col-md-6">
            <input type="text" id="host" name="host" [disabled]="isViewMode" class="form-control" maxlength="50" [(ngModel)]="storeForm.host" (focusout)="clearErrorIfValidRequire($event)">
            <div class="text-error" for="host"></div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label for="countryCode" class="control-label col-md-2">{{location ? ('LOCATION' | translate) : location}}</label>
          <div class="col-md-6">
            <select id="countryCode" name="countryCode" [disabled]="isViewMode || !authService.isSystemAdmin" class="form-control" [(ngModel)]="storeForm.storeCountry" (focusout)="clearErrorIfValidRequire($event)"
              required="required">
              <option class="default-option" value="">{{defaultSelectOption ? ('SELECT_OPTION' | translate) : defaultSelectOption}}</option>
              <option *ngFor="let country of countries" [value]="country">{{country}}</option>
            </select>
            <div class="text-error" for="countryCode"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="salesTargetMessage" class="control-label col-md-2">{{salesTargetMessage ? ('SALES_TARGET_MESSAGE' | translate) : salesTargetMessage}}</label>
          <div class="col-md-6">
            {{limit26 ? ('LIMIT_26' | translate) : limit26}}<span id="chars">{{characterleft}}</span>{{charactersLeft ? ('CHARACTERS_LEFT' | translate) : charactersLeft}}
            <textarea maxlength="26" rows="2" type="text" id="salesTargetMessage" name="salesTargetMessage" [disabled]="isViewMode" class="form-control" (keyup)="count(storeForm.salesTargetMessage)" [(ngModel)]="storeForm.salesTargetMessage" (focusout)="clearErrorIfSalesTargetMessageTargetMessageLimitCharacters($event)"></textarea>
            <div class="text-error" for="salesTargetMessage"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="salesTarget" class="control-label col-md-2">{{salesTarget ? ('SALES_TARGET' | translate) : salesTargetMessage}}</label>
          <div class="col-md-6">
            <input type="text" id="salesTarget" name="salesTarget" [disabled]="isViewMode" class="form-control" maxlength="30" [(ngModel)]="storeForm.salesTarget" (focusout)="clearErrorIfValidNumber($event)">
            <div class="text-error" for="salesTarget"></div>
          </div>
          <div class="col-md-1">
            <p class="p-left-middle">{{storeForm.currency}}</p>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="averageBasketTarget" class="control-label col-md-2">{{avergeBasketTarget ? ('AVERAGE_BASKET_TARGET' | translate) : avergeBasketTarget}}</label>
          <div class="col-md-6">
            <input type="text" id="averageBasketTarget" name="averageBasketTarget" [disabled]="isViewMode" class="form-control" maxlength="30" [(ngModel)]="storeForm.averageBasketTarget" (focusout)="clearErrorIfValidNumber($event);">
            <div class="text-error" for="averageBasketTarget"></div>
          </div>
          <div class="col-md-1">
            <p class="p-left-middle">{{storeForm.currency}}</p>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="collectSalesTarget" class="control-label col-md-2">{{sephoraCollectSalesTarget ? ('SEPHORA_COLL_SALES_TARGET' | translate) : sephoraCollectSalesTarget}}</label>
          <div class="col-md-6">
            <input type="text" id="collectSalesTarget" name="collectSalesTarget" [disabled]="isViewMode" class="form-control" maxlength="30" [(ngModel)]="storeForm.collectSalesTarget" (focusout)="clearErrorIfValidNumber($event);">
            <div class="text-error" for="collectSalesTarget"></div>
          </div>
          <div class="col-md-1">
            <p class="p-left-middle">{{storeForm.currency}}</p>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="oobaSalesTarget" class="control-label col-md-2">{{oobaSalesTarget ? ('OOBA_SALES_TARGET' | translate) : oobaSalesTarget}}</label>
          <div class="col-md-6">
            <input type="text" id="oobaSalesTarget" name="oobaSalesTarget" [disabled]="isViewMode" class="form-control" maxlength="30" [(ngModel)]="storeForm.oobaSalesTarget" (focusout)="clearErrorIfValidNumber($event);">
            <div class="text-error" for="oobaSalesTarget"></div>
          </div>
          <div class="col-md-1">
            <p class="p-left-middle">{{storeForm.currency}}</p>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="countryCode" class="control-label col-md-2">{{redeemable ? ('REDEEMABLE' | translate) : redeemable}}</label>
          <div class="col-md-6">
            <form name="redeemableForm">
              <input type="radio" [disabled]="isViewMode" class="col-md-1" name="redeemable"  (click)="radioSelection(1)" [checked]=isRedeemable value="1" >{{enable ? ('ENABLE' | translate) : enable}}<br>
              <input type="radio" [disabled]="isViewMode" class="col-md-1" name="redeemable"  (click)="radioSelection(0)" [checked]=!isRedeemable value="0" >{{disable ? ('DISABLE' | translate) : disable}}<br>
            </form>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="countryCode" class="control-label col-md-2">{{stockVisible ? ('STOCK_VISIBLE' | translate) : stockVisible}}</label>
          <div class="col-md-6">
            <form>
              <input type="radio" [disabled]="isViewMode" class="col-md-1" name="stockVisible"  (click)="radioStockSelection(1)" [checked]=isStockVisible value="1" >{{enable ? ('ENABLE' | translate) : enable}}<br>
              <input type="radio" [disabled]="isViewMode" class="col-md-1" name="stockVisible"  (click)="radioStockSelection(0)" [checked]=!isStockVisible value="0" >{{disable ? ('DISABLE' | translate) : disable}}<br>
            </form>
          </div>
        </div>
      </div>
	  
	  <div class="form-group">
        <div class="row">
          <label for="countryCode" class="control-label col-md-2">{{serviceVisible ? ('SERVICE_VISIBLE' | translate) : serviceVisible}}</label>
          <div class="col-md-6">
            <form>
              <input type="radio" [disabled]="isViewMode" class="col-md-1" name="serviceVisible"  (click)="radioServiceSelection(1)" [checked]=isServiceVisible value="1" >{{enable ? ('ENABLE' | translate) : enable}}<br>
              <input type="radio" [disabled]="isViewMode" class="col-md-1" name="serviceVisible"  (click)="radioServiceSelection(0)" [checked]=!isServiceVisible value="0" >{{disable ? ('DISABLE' | translate) : disable}}<br>
            </form>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="countryCode" class="control-label col-md-2">{{endlessAisleTrackOrder ? ('ENDLESS_AISLE_TRACK_ORDER' | translate) : endlessAisleTrackOrder}}</label>
          <div class="col-md-6">
            <form>
              <input type="radio" [disabled]="isViewMode" class="col-md-1" name="endlessAisle"  (click)="radioEndlessAisleSelection(1)" [checked]=isEndlessAisle value="1" >{{enable ? ('ENABLE' | translate) : enable}}<br>
              <input type="radio" [disabled]="isViewMode" class="col-md-1" name="endlessAisle"  (click)="radioEndlessAisleSelection(0)" [checked]=!isEndlessAisle value="0" >{{disable ? ('DISABLE' | translate) : disable}}<br>
            </form>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="countryCode" class="control-label col-md-2">{{state ? ('STATE' | translate) : state}}</label>
          <div class="col-md-6">
            <form>
              <input type="radio" [disabled]="numberOfUsers || numberOfTerminals || isViewMode" class="col-md-1" name="isActive" (click)="radioIsActiveSelection(1)" [checked]=active value="1" >{{enable ? ('ENABLE' | translate) : enable}}<br>
              <input type="radio" [disabled]="numberOfUsers || numberOfTerminals || isViewMode || (!this.authService.isSystemAdmin && !this.authService.isRetailOps)" class="col-md-1" name="isActive" (click)="radioIsActiveSelection(0)" [checked]=!active value="0" >{{disable ? ('DISABLE' | translate) : disable}}<br>
            </form>
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="isEditMode || isViewMode">
        <div class="row">
          <label class="control-label col-md-2">{{editHistory ? ('EDIT_HISTORY' | translate) : editHistory}}</label>
          <div class="col-md-6">
            <textarea rows="4" readonly type="text" id="editHistory" name="editHistory" class="form-control" [(ngModel)]="editHistoriesText"></textarea>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-12">
          <div class="pull-right">
            <button *ngIf="!isEditMode && !isViewMode" type="submit" class="btn btn-danger" (click)="submitStoreForm()">{{createStoreText ? ('CREATE_STORE' | translate) : createStoreText}}</button>
            <button *ngIf="isEditMode" type="submit" class="btn btn-danger" (click)="submitStoreForm()">{{save ? ('SAVE' | translate) : save}}</button>
            <button *ngIf="!isViewMode" type="button" class="btn btn-dark" (click)="cancelForm()">{{cancel ? ('CANCEL' | translate) : cancel}}</button>
            <button *ngIf="isEditMode && authService.isSystemAdmin" [disabled]="numberOfUsers || numberOfTerminals" type="submit" class="btn btn-dark" (click)="showDeleteStoreConfirmDialog()">{{deleteStoreText ? ('DELETE_STORE' | translate) : deleteStoreText}}</button>
            <button *ngIf="isViewMode"type="button" class="btn btn-dark" (click)="cancelForm()">{{close ? ('CLOSE' | translate) : close}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<message-modal></message-modal>