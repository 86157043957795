import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {AuthService} from 'src/app/services/auth.service';
import {UtilService} from 'src/app/services/util.service';
import {ENDPOINTS} from 'src/app/common/endpoints';

@Injectable()
export class BookingService {
  constructor(private http: HttpClient, private utilService: UtilService, protected authService: AuthService) {}

  fetchResources(storeCode: string): Observable<any> {
    return this.http
      .get(ENDPOINTS.BOOKING_FETCH_RESOURCES + "/" + storeCode, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
  }

  filter(
    storeCode: string,
    downloadReport: boolean,
    page: number,
    keywords: string,
    startDate: string,
    endDate: string,
    activityType: string,
    activityIds: any[],
    resourceIds: any[],
    statusIds: any[]
  ): Observable<any> {
    let conditions = {
      storeCode,
      downloadReport,
      page,
      startDate,
      endDate,
      keywords,
      activityType,
      activityIds,
      resourceIds,
      statusIds
    };
    return this.http
      .post(ENDPOINTS.BOOKING_FILTER, conditions, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
  }

  updateStatus(
    confirmationNumber: string,
    status: string,
    storeCode: string
  ): Observable<any> {
    let payload = {
      status,
      modifiedBy: this.authService.userId,
      storeCode
    };
    return this.http
      .post(ENDPOINTS.BOOKING_UPDATE_STATUS + "/" + confirmationNumber, payload, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
  }

  getOneBooking(storeCode: string, confirmationNumber: string): Observable<any> {
    const url = `${ENDPOINTS.BOOKING_BY}/${storeCode}/${confirmationNumber}`;
    
    const headers = {
      'Content-Type': 'application/json',
      'Token': this.authService.token,
      'Accept-Language': localStorage.getItem('selectedLanguage') as string, 
    };
  
    return this.http
      .get(url, { headers, observe: 'response', responseType: 'json' })
      .pipe(
        map(this.utilService.extractData), 
        catchError(this.utilService.handleError)  
      );
  }
  
  

}