import { Component, OnInit,ElementRef, Renderer2 } from "@angular/core";
import { DomSanitizer,SafeHtml} from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DomoService } from 'src/app/services/domo.service';
import { CookieService } from "ngx-cookie-service";
import { disableCursor } from "@fullcalendar/core/internal";

declare var $: any;

@Component({
  selector: 'domo-component',
  templateUrl: './domo.component.html',
  styleUrls: ['./domo.component.css']
})
export class DomoComponent implements OnInit {
    safeHtml!: SafeHtml;

    constructor(
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private domoService: DomoService,
        private renderer: Renderer2,
        private el: ElementRef,
        private cookieService: CookieService
        ) {}
    domoUrl: string = '';
    authenticated: boolean = false;
    token: string = '';
    isLoading: boolean = true;
    source: string = '';
    storeCode: string = '';
    entityName: string = '';

    ngOnInit() {
      this.isLoading = true;
      this.sendFilters();
    }

  sendFilters() {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];  // 'token' is the name of the query parameter
      this.source = params['source'];
      });
  
    if(!this.token) {
     this.token = this.cookieService.get('token');
    }

    this.domoService.sendFilters(this.token,this.source).subscribe(
        res => {
          this.entityName = res.entityName;
          if(res.embedId && res.embedToken) {
            var responseForm = "";
            responseForm +="<form id=\"form\" action=\"https://embed.domo.com/embed/pages/" + res.embedId + "\" method=\"post\">";
            responseForm += "<input type=\"hidden\" name=\"embedToken\" value=\"" + res.embedToken + "\">";
            responseForm += "</form>";
            this.renderDynamicContent(responseForm);
            this.isLoading = true;
          }
         this.authenticated = true;
        },
        err => {
            this.authenticated = false;
            this.isLoading = false;
            alert('error calling domo');
        }
    );  
  }

  private renderDynamicContent(htmlRes: any) {
     this.safeHtml = htmlRes;
      // this.isLoading = false;
      var iframe = document.querySelector("iframe");
      var iframeWindow = document.querySelector("iframe")?.contentWindow;
      if(iframe) {
        const checkIframeLoaded = setInterval(() => {
          const iframeDocument = iframeWindow?.document;
          if (iframeDocument && iframeDocument.body) {
            iframeDocument.body.innerHTML = this.safeHtml as string;
            iframeDocument.body.querySelector('form')?.submit();
            this.isLoading = false;
            clearInterval(checkIframeLoaded);  // Clear the interval once loaded
          }
        }, 100); 
      }
      // if (iframe) {
      //   iframe.onload = () => {
      //     const iframeDocument = iframeWindow?.document;  
      //     if (iframeDocument && iframeDocument.body) {
      //       iframeDocument.body.innerHTML = this.safeHtml as string;
      //       iframeDocument.body.querySelector('form')?.submit();
      //       this.isLoading = false;
      //     } else {
      //       console.error("Iframe document body not accessible");
      //     }
      //   };
      // }
  }


    
  }
