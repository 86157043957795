import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {UtilService} from 'src/app/services/util.service';
import { ENDPOINTS } from '../common/endpoints';

@Injectable()
export class DomoService {
  constructor(private http: HttpClient, private utilService: UtilService) {}
  sendFilters(token: string): Observable<any> {
    return this.http
      .get(ENDPOINTS.DOMO, {headers:{'Content-Type': 'application/json', 'Token': token, 'Accept-Language': 'en-SG'}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
 }
}