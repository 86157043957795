import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {NavbarComponent} from 'src/app/components/navbar/navbar.component';
import {LoginComponent} from 'src/app/components/login/login.component';
import {ChangeWebPassComponent} from 'src/app/components/changewebpass/changewebpass.component';
import {PasswordComponent} from 'src/app/components/password/password.component';
import {UserComponent} from 'src/app/components/user/user.component';
import {MainComponent} from 'src/app/components/main/main.component';
import {StoreComponent} from 'src/app/components/store/store.component';
import {TerminalComponent} from 'src/app/components/terminal/terminal.component';
import {ResourceComponent} from 'src/app/components/resource/resource.component';
import {CountryComponent} from 'src/app/components/country/country.component';
import {AuditComponent} from 'src/app/components/audit/audit.component';
import {OrderComponent} from 'src/app/components/order/order.component';
import {AuthGuard} from 'src/app/services/auth-guard.service';
import { QrComponent } from './components/qr/qr.component';
import { DomoComponent } from './components/domo/domo.component';
import { BookingDetailsComponent} from './components/booking-details/booking-details.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
        {
            path: '',
            component: NavbarComponent,
            canActivateChild: [AuthGuard],
            children: [
                {path: 'management', component: MainComponent},
                {path: 'user/view/:userId', component: UserComponent},
                {path: 'user/edit/:userId', component: UserComponent},
                {path: 'user/new', component: UserComponent},
                {path: 'store/edit/:storeId', component: StoreComponent},
                {path: 'store/view/:storeId', component: StoreComponent},
                {path: 'store/new', component: StoreComponent},
                {path: 'terminal/edit/:terminalId', component: TerminalComponent},
                {path: 'terminal/view/:terminalId', component: TerminalComponent},
                {path: 'terminal/new', component: TerminalComponent},
                {path: 'country/edit/:countryId', component: CountryComponent},
                {path: 'audit/view/:auditId', component: AuditComponent},
                {path: 'order/view/:orderId', component: OrderComponent},
                {path: 'booking/view/:storeCode/:confirmationNumber', component: BookingDetailsComponent},
                {path: 'resource/new/:storeCountry/:storeCode', component: ResourceComponent},
                {path: 'resource/edit/:storeCountry/:storeCode/:id', component: ResourceComponent},
                {path: 'resource/view/:storeCountry/:storeCode/:id', component: ResourceComponent},
                {path: '', redirectTo: 'management', pathMatch: 'full'}
            ]
       }
    ]
},
{path: 'login', component: LoginComponent},
{path: 'logout', component: LoginComponent},
{path: 'recover', component: PasswordComponent},
{path: 'reset', component: PasswordComponent},
{path: 'change-pass', component: ChangeWebPassComponent},
{path: 'login/authenticate/:source', component: QrComponent},
{path: 'login/authenticate', component: QrComponent},
{path: 'domo-view', component: DomoComponent, pathMatch: 'full'},
{path: '**', redirectTo: 'management', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
