<div id="iframe-container" style="display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; margin-top: 40px;">
    <div  style="height: 30px;width: 100vh;text-align: center;vertical-align: middle">
      <h3>Viewing for <b>{{entityName}}</b></h3>
    </div>
    <span [hidden]="!isLoading" class="loader"></span>
    <div id = "iframe-container" class="iframecontainer" style="top: 0px; position: relative">
        <iframe id="dynamic-container" name="dynamiciframe" [srcdoc] = "safeHtml" style="width: 100vw; height: 100vh;" scrolling="yes">  
        </iframe>
    </div> 
</div>
