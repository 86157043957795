import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';

import {UtilService} from 'src/app/services/util.service';
import {ENDPOINTS} from 'src/app/common/endpoints';
import {CODINGCONSTANTS} from 'src/app/common/coding-constants';

@Injectable()
export class AuthService {
  isLoggedIn = false;
  redirectUrl: string = '/user';
  authorities: any[] = [];
  countries: string[] = [];
  isSystemAdmin: boolean = false;
  isRetailOps: boolean = false;
  isSM: boolean = false;
  isSMT: boolean = false;
  isBM: boolean = false;
  isBR: boolean = false;
  location: string = '';
  storeCountry: string = '';
  storeCode: string = '';
  storeName: string = '';
  firstName: string = 'Guest';
  id: string = '0';
  expiredWebPass : number = 0;
  userId : string = '';
  fullName: string = '';
  password : string = '';
  token : string = '';
  isOAuth2Authenticate : boolean = false;
  oktaLogoutUrl = '';
  oktaIdToken = '';

  constructor(private http: HttpClient, private cookieService: CookieService, private utilService: UtilService) {}

  login(username: string, password: string): Observable<any> {
    this.userId = username;
    this.password = password;
    this.isLoggedIn = false;
    this.authorities = [];
    let formData = new FormData();
    formData.append('userId', username);
    formData.append('password', password);
    return this.http
      .post(ENDPOINTS.LOGIN, formData, {observe: 'response', responseType: 'json'})
      .pipe(map(this.loginSuccess.bind(this)),
      catchError(this.handleError));
 }

  otpLogin(emailId: string, otp: string): Observable<any> {
    const payload = {
      emailId,
      otp
    };
    return this.http
      .post(ENDPOINTS.OTP_LOGIN, payload, {headers:{'Content-Type': 'application/json'}, observe: 'response', responseType: 'json'})
      .pipe(map(this.loginSuccess.bind(this)),
      catchError(this.handleError)
    );
  }

  oAuth2Login() {
    this.isLoggedIn = false;
    this.authorities = [];
    return this.http
      .get(ENDPOINTS.OAUTH2_OKTA_AUTHENTICATE, {observe: 'response', responseType: 'json'})
      .pipe(map(this.loginSuccess.bind(this)),
      catchError(this.utilService.handleError));
 }

  oAuth2MobileLogin() {
    return this.http
      .get(ENDPOINTS.OAUTH2_AUTHENTICATE, {observe: 'response', responseType: 'json'})
      .pipe(map(this.loginSuccess.bind(this)),
      catchError(this.utilService.handleError));
 }

  logout() {
    return this.http
      .post(ENDPOINTS.LOGOUT, {}, {headers:{'Content-Type': 'application/json', 'Token': this.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.logoutSuccess.bind(this)),
      catchError(this.utilService.handleError));
 }

  logoutOAuth2() {
    return this.http
    .post(ENDPOINTS.OAUTH2_OKTA_LOGOUT, {}, {observe: 'response', responseType: 'json'})
    .pipe(map(this.oAuth2LogoutSuccess.bind(this)),
    catchError(this.utilService.handleError));
 }

  changeBAPassword(userId: string, body: any) {
    return this.http
      .put(ENDPOINTS.USER_BY + userId + '/change_app_password', body, {headers:{'Content-Type': 'application/json', 'Token': this.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  changeWebPortalPassword(userId: string, body: any) {
    return this.http
      .put(ENDPOINTS.USER_BY + userId + '/change_web_password', body, {headers:{'Content-Type': 'application/json', 'Token': this.token, 'Accept-Language': localStorage.getItem('selectedLanguage') as string}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
 }

  private loginSuccess(res: any) {
    if (res && res.status == 200) {
      this.isLoggedIn = true;
      let body: any = res.body;
      this.id = body.id;
      this.userId = body.userId;
      this.fullName = body.fullName;
      this.location = body.location;
      this.storeCountry = body.storeCountry;
      this.expiredWebPass = body.expired;
      this.storeCode = body.storeCode;
      this.storeName = body.storeName;
      this.authorities = body.authorities;
      this.countries = body.countries;
      this.isSystemAdmin = this.authorities && this.authorities.some(it => it.authority == CODINGCONSTANTS.SA_ROLE);
      this.isRetailOps = this.authorities && this.authorities.some(it => it.authority == CODINGCONSTANTS.RO_ROLE);
      this.isSM = this.authorities && this.authorities.some(it => it.authority == CODINGCONSTANTS.SM_ROLE);
      this.isSMT = this.authorities && this.authorities.some(it => it.authority == CODINGCONSTANTS.SMT_ROLE);
      this.isBM = this.authorities && this.authorities.some(it => it.authority == CODINGCONSTANTS.BM_ROLE);
      this.isBR = this.authorities && this.authorities.some(it => it.authority == CODINGCONSTANTS.BR_ROLE);
      this.isOAuth2Authenticate = body.oauth2Authenticated;
      this.token = body.token;
      if (body.fullName) this.firstName = body.fullName.split(' ')[0];
      this.cookieService.set('remember', 'true', undefined);
      this.cookieService.set('token', body.token, undefined);
    
      localStorage.setItem('id', body.id)
      localStorage.setItem('userId', body.userId);
      localStorage.setItem('firstName', this.firstName);
      localStorage.setItem('fullName', body.fullName);
      localStorage.setItem("isOAuth2Authenticate", String(body.oauth2Authenticated));
      localStorage.setItem('authorities', JSON.stringify(body.authorities));
      localStorage.setItem('storeCode', body.storeCode);
      localStorage.setItem('storeName', body.storeName);
      localStorage.setItem('storeCountry', body.storeCountry);
      localStorage.setItem('location', body.location);
      localStorage.setItem('countries', JSON.stringify(body.countries));
      localStorage.setItem('ecomUrl', body.ecomUrl);

      return body;
   }
    return res;
 }

  private oAuth2LogoutSuccess(res: any) {
    if (res) {
      this.isOAuth2Authenticate = false;
      let body: any = res.body;
      this.oktaLogoutUrl = body.logoutUrl;
      this.oktaIdToken = body.idToken;
      return body;
   }
    return res;
 }

  private logoutSuccess(res: any) {
    this.isLoggedIn = false;
    this.authorities = [];
    return res;
 }

  handleError(error: any) {
    return throwError(error);
 }

}
