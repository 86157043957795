<div class="user-wrapper">
    <div>
      <div class="table-title">
        Appointment Details 
      </div>
    </div>
    <div *ngIf="isLoading" class="loading-spinner">
      <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg" />
    </div>
    <div class="sephora-container">
      <form class="form-horizontal">
        <div class="form-group">
          <div class="col-md-11">
            <div class="pull-right">
              <button type="button" (click)="cancelForm()" class="btn btn-dark">close</button>
            </div>
          </div>
        </div>
        <div class="table-details"><br /></div>
        <div class="form-group">
          <div class="row">
            <label for="status" class="control-label col-md-2">Status </label>
            <div class="col-md-6">
              <input type="text" id="status" name="status" [value]="bookingData?.bamStatusText" [disabled]="true" class="form-control">
            </div>
          </div>
        </div>
        <div class="form-group">
            <div class="row">
              <label for="id" class="control-label col-md-2">Date</label>
              <div class="col-md-6">
                <input type="text" id="id" name="id" [value]="bookingData?.date" [disabled]="true" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label for="timeSlot" class="control-label col-md-2">Time </label>
              <div class="col-md-6">
                <input type="text" id="timeSlot" name="timeSlot" [value]="bookingData?.timeSlot" [disabled]="true" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label for="activityName" class="control-label col-md-2">Activity Name</label>
              <div class="col-md-6">
                <input type="text" id="activityName"  [value]="bookingData?.activityName" name="activityName" [disabled]="true" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label for="storeName" class="control-label col-md-2">Store Name </label>
              <div class="col-md-6">
                <input type="text" id="storeName" name="storeName" [value]="bookingData?.locationName" [disabled]="true" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label for="resource" class="control-label col-md-2">Resource </label>
              <div class="col-md-6">
                <input type="text" id="resource" [value]="bookingData?.bookedByClientBaInfo?.displayName" name="resource" [disabled]="true" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label for="confirmationNumber" class="control-label col-md-2">Confirmation Number</label>
              <div class="col-md-6">
                <input type="text" id="confirmationNumber" [value]="bookingData?.confirmationNumber" name="confirmationNumber" [disabled]="true" class="form-control">
              </div>
            </div>
          </div>
    
      </form>
    </div>
  </div>
  
  <div class="user-wrapper">
    <div>
      <div class="table-title">
        Customer Information 
      </div>
    </div>
    <div class="sephora-container">
      <form class="form-horizontal">
  
        <div class="form-group">
          <div class="row">
            <label for="beautyPassNumber" class="control-label col-md-2">Beauty Pass Number</label>
            <div class="col-md-6">
              <input type="text" id="beautyPassNumber" [value]="bookingData?.beautyInsiderId" name="beautyPassNumber" [disabled]="true" class="form-control">
            </div>
          </div>
        </div>
        <div class="form-group">
            <div class="row">
              <label for="customerName" class="control-label col-md-2">Name </label>
              <div class="col-md-6">
                <input type="text" id="customerName" name="id" [value]="bookingData?.clientName" [disabled]="true" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label for="email" class="control-label col-md-2">Email </label>
              <div class="col-md-6">
                <input type="text" id="email" name="email" [value]="bookingData?.clientExternalId" [disabled]="true" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label for="phone" class="control-label col-md-2">Phone No.</label>
              <div class="col-md-6">
                <input type="text" id="phone" [value]="bookingData?.customerPhoneNumber" name="phone" [disabled]="true" class="form-control">
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="control-label col-md-2">What Client Shared </label>
              <div class="col-md-6">
                <textarea rows="4" readonly type="text" name="clientShared" class="form-control">{{ formatClientQuestionsWithConsent(bookingData?.questionsAnswersDetails) }}</textarea>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="control-label col-md-2">Feedback Details </label>
              <div class="col-md-6">
                <textarea rows="3" readonly class="form-control">{{ bookingData?.feedback ? 'Rating: ' + bookingData.feedback.rating + '\nMessage: ' + bookingData.feedback.message : 'No feedback provided' }} </textarea>
              </div>
            </div>
          </div>
      </form>
    </div>
  </div>
  