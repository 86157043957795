import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from 'src/app/services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { CONSTANTS } from 'src/app/common/constants'
import {DomSanitizer} from '@angular/platform-browser';
import { cl } from "@fullcalendar/core/internal-common";


@Component({
  selector: "qr.component",
  templateUrl: "./qr.component.html",
  styleUrls: ["./qr.component.css"]
})
export class QrComponent implements OnInit {
  title = "QR Code Generator";
  elementType = "url";
  value = "/login/authenticate";
  source = 'baWebApp'; // Declare a variable to store the source
  baappurl = '';
  timeouta: any;
  timeoutb: any;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private sanitizer:DomSanitizer
  ) {}

  ngOnInit() {
    console.log('QR Component');
    // Read the 'source' parameter from the query string
    this.route.params.subscribe(params => {
      this.source = params['source'] || 'defaultSource'; // Use a default value if 'source' is not present
      this.loginWithBAApp(this.source);
    });
  }

  loginWithBAApp(source: string) {
    console.log('Login with BA App, Source:', source);
    this.showQRCode(source);
  }

  showQRCode(source: string) {
    this.authService.loginWithApp(source).subscribe(response => {
      const url = URL.createObjectURL(response.blob);
      const qrImage = document.getElementById('qrImage') as HTMLImageElement;
      qrImage.src = url;
      this.openModal();
      const now = new Date();
      const isoString = now.toISOString();
      const appUrl= "baapp://auth?location=0:0:0:0:0:0:0:1&source="+source+"&token="+response.token+"&timestamp="+isoString;
      if(this.isValidUrl(appUrl)) {
        this.baappurl = appUrl;
      }
      // Check if the user has scanned the QR code
      //const delay = this.getRandomDelay(10000, 20000); // Random delay between 10 and 20 seconds
      const delay = 5000; // check every 5 seconds
      this.timeouta = setTimeout(() => {
        this.pollStatus(response.token);
      }, delay);
    },
    error => {
      console.error('Error fetching QR code:', error);
    });
  }

  sanitize(url: string) {
    if(url == this.baappurl) {
      return this.sanitizer.bypassSecurityTrustUrl(url);
    } else {
      return null;
    }
  }

  isValidUrl(url: any) {
    try {
        new URL(url);  // If the URL is invalid, this will throw an error
        return true;
    } catch (e) {
        return false;
    }
}

  pollStatus(token: any) {
    const startTime = Date.now();
    const retryDuration = 3 * 60 * 1000; // 3 minutes in milliseconds

    const poll = () => {
        // Calculate elapsed time
        const elapsedTime = Date.now() - startTime;

        // If elapsed time exceeds retryDuration, stop retrying
        if (elapsedTime >= retryDuration) {
            this.postFailureMessage();
            this.showErrorPopup("Stopping retries, the QR code is no longer valid, refresh and generate a new one");
            console.log('Stopped retrying after 3 minutes');
            setTimeout(() => {
              window.top?.location.reload();
            },2000);
            return;

        }

        // Check status
        this.checkStatus(token).then((data: any) => {
            if (data.status === "true" || data.status == "false") {
                console.log('stopping retries, status = '+data.status);
                if(data.status == "false") {
                  this.showErrorPopup("Stopping retries, status is false");
                  setTimeout(() => {
                    window.top?.location.reload();
                  },2000);
                 }
                return;
            }

            // If status is unknown, schedule the next check
            console.log('Status is false, will retry...');
            //const delay = this.getRandomDelay(10000, 20000);
            const delay = 5000;
            this.timeoutb = setTimeout(poll, delay); // retry every 5 seconds
        }).catch(error => {
            console.error('Error checking status:', error);
        });
    };

    // Start polling
    poll();
  }

  showErrorPopup(message: string) {
    this.notificationsService.error(
      CONSTANTS.ERROR,
      message,
      {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
     }
    );
  }

  checkStatus(token: any): Promise<any> {
      return new Promise((resolve, reject) => {
          this.authService.checkScanStatus(token).subscribe((data: any) => {
              console.log("poll status", data);
              window.parent.postMessage({ type: 'authHandler', data: data }, '*');
              resolve(data);
          }, error => {
              console.error('Error checking status:', error);
              reject(error);
          });
      });
  }

  getRandomDelay(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  openModal() {
    const modal = document.getElementById('qrModal') as HTMLElement;
    modal.style.display = 'block';
  }

  closeModal() {
    const modal = document.getElementById('qrModal') as HTMLElement;
    clearTimeout(this.timeouta);
    clearTimeout(this.timeoutb);
    // this.showErrorPopup("Stopping retries");
    this.closeIFrame();
    modal.style.display = 'none';
  }

  postFailureMessage() {
    var closedata = {
      "status": "false", 
      "ba": null
    };
    window.parent.postMessage({ type: 'authHandler', data: closedata }, '*');
  }

  closeIFrame() {
    this.postFailureMessage();
    if(!window.top?.location.href.includes('authenticate')) {
    window.top?.location.reload();
    }
  }
}
