<div class="user-wrapper">
  <div>
    <div class="table-title">
      {{resourceManagement ? ('RESOURCE_MANAGEMENT' | translate) : resourceManagement}}
    </div>
  </div>
  <div *ngIf="isLoading" class="loading-spinner">
    <img class="loading-spinner-icon" src="../assets/images/loading-spinner-icon.svg" />
  </div>
  <div class="sephora-container" [class.opaque]="isLoading">
    <div class="form-horizontal">
      <div class="form-group">
        <div class="col-md-11">
          <div class="pull-right">
            <button *ngIf="!isViewMode" type="submit" class="btn btn-danger" (click)="submitForm()">{{publish ? ('PUBLISH' | translate) : publish}}</button>
            <button *ngIf="isEditMode" type="submit" class="btn btn-dark" (click)="showDeleteConfirmDialog()">{{delete ? ('DELETE' | translate) : delete}}</button>
            <button *ngIf="!isViewMode" type="button" class="btn btn-dark" (click)="cancelForm()">{{cancel ? ('CANCEL' | translate) : cancel}}</button>
            <button *ngIf="isViewMode"type="button" class="btn btn-dark" (click)="cancelForm()">{{close ? ('CLOSE' | translate) : close}}</button>
          </div>
        </div>
      </div>
      <div class="table-details"><br /></div>

      <div class="form-group">
        <div class="row">
          <label class="control-label col-md-2 font-weight-bold">{{resourceForLabel ? ('RESOURCE_FOR' | translate) : resourceForLabel}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-2">
                <select class="form-control-sm text-danger" [disabled] = "!authService.isSystemAdmin || isViewMode || isEditMode" id="country" name="country" [(ngModel)]="country"
                (focusout)="clearErrorIfValidRequire($event.target)" required="required">
                  <option class="default-option" [value]="country">{{country}}</option>
                </select>
                <span class="underline"></span>
                <div class="text-error" for="country"></div>
              </div>
              <div class="col-md-2">
                <select class="form-control-sm text-danger" id="storeCode" name="storeCode" [disabled] = "!country || isViewMode || isEditMode || resourceForm.storeCode" [(ngModel)]="resourceForm.storeCode"
                (focusout)="clearErrorIfValidRequire($event.target)" required="required" (change)="onSelectStore($event)">
                  <option *ngFor="let store of stores" [value]="store.storeCode" [disabled]="store.storeCode === resourceForm.storeCode">{{store.storeName}}</option>
                </select>
                <span class="underline"></span>
                <div class="text-error" for="storeCode"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label class="control-label col-md-2">{{statusLabel ? ('STATUS' | translate) : statusLabel}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" [disabled]="isViewMode" name="status" [value]=0 [(ngModel)]="resourceForm.status" checked>
              <label class="form-check-label">{{inactiveText ? ('INACTIVE' | translate) : inactiveText}}</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" [disabled]="isViewMode" name="status" [value]=1 [(ngModel)]="resourceForm.status">
              <label class="form-check-label">{{activeText ? ('ACTIVE' | translate) : activeText}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label for="name" class="control-label col-md-2">{{resourceNameLabel ? ('RESOURCE_NAME_LABEL' | translate) : resourceNameLabel}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <input type="text" [disabled]="isViewMode" id="name" name="name" class="form-control" [(ngModel)]="resourceForm.name" (focusout)="clearErrorIfValidRequire($event)">
            <div class="text-error" for="name"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label class="control-label col-md-2">{{expertiseLabel ? ('EXPERTISE_LABEL' | translate) : expertiseLabel}}</label>
          <div class="col-md-6">
            <ng-multiselect-dropdown id="expertiseMultiSelect" [disabled]="isViewMode"
            [placeholder]="multiselectPlaceHolder"
            [settings]="expertiseDropdownSettings"
            [data]="expertiseDropdownList"
            [(ngModel)]="resourceForm.expertise"
            >
            </ng-multiselect-dropdown>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label class="control-label col-md-2">{{activityGroupLabel ? ('ACTIVITY_GROUP_LABEL' | translate) : activityGroupLabel}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <ng-multiselect-dropdown id="activityGroupMultiSelect" (focusout)="validateSelectedActivityGroups($event)" [disabled]="isViewMode"
            [placeholder]="multiselectPlaceHolder"
            [settings]="activityGroupDropdownSettings"
            [data]="activityGroupDropdownList"
            [(ngModel)]="selectedActivityGroups"
            (onSelect)="onActivityGroupSelect($event)"
            (onDeSelect)="onActivityGroupDeSelect($event)"
            (onSelectAll)="onActivityGroupSelectAll($event)"
            (onDeSelectAll)="onActivityGroupDeselectAll($event)"
            >
            </ng-multiselect-dropdown>
            <div class="text-error" for="activityGroupMultiSelect"></div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label class="control-label col-md-2">{{activityNameLabel ? ('ACTITIVY_NAME_LABEL' | translate) : activityNameLabel}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <ng-multiselect-dropdown id="activityNameMultiSelect" (focusout)="validateSelectedActivityNames($event)" [disabled]="isViewMode"
            [placeholder]="multiselectPlaceHolder"
            [settings]="activityNameDropdownSettings"
            [data]="activityNameDropdownList"
            [(ngModel)]="selectedActivityNames"
            (onSelect)="onActivityNameSelect($event)"
            (onDeSelect)="onActivityNameDeSelect($event)"
            (onSelectAll)="onActivityNameSelectAll($event)"
            >
            </ng-multiselect-dropdown>
            <div class="text-error" for="activityNameMultiSelect"></div>
          </div>
        </div>
      </div>
      
      <div class="form-group">
        <div class="row">
          <label class="control-label col-md-2">{{bookByBALabel ? ('BOOK_BY_BA' | translate): bookByBALabel}}</label>
          <div class="col-md-6">
              <input type="checkbox" id="bookByBA" name="bookByBAName" [(ngModel)]="resourceForm.bookByBAName" (click) = "checkboxByBAName()">
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label class="control-label col-md-2">{{scheduleLabel ? ('SCHEDULE_LABEL' | translate) : scheduleLabel}}<sup class="supertext">*</sup></label>
          <div class="col-md-6">
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" (click) = "changeOneOff()" [disabled] = " (resourceForm.openingSlots.length > 0) || isViewMode || isEditMode" name="schedule" [value]=0 [(ngModel)]="resourceForm.scheduleType" checked>
              <label class="form-check-label">{{recurringLabel ? ('RECURRING_LABEL' | translate) : recurringLabel}}</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio" class="form-check-input" (click) = "changeOneOff()" [disabled] = " (resourceForm.openingSlots.length > 0) || isViewMode || isEditMode" name="schedule" [value]=1 [(ngModel)]="resourceForm.scheduleType">
              <label class="form-check-label">{{oneOffLabel ? ('ONE_OFF_LABEL' | translate) : oneOffLabel}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <label class="control-label col-md-2"></label>
          <div class="col-md-6">
          <label class="control-label col-md-2 pl-0 font-weight-bold">{{dateLabel ? ('DATE_LABEL' | translate) : dateLabel}}</label>
          <div class="form-inline">
              <div class="form-inline">
                <label for="fromDate" class="col-form-label">{{fromLabel ? ('FROM_LABEL' | translate) : fromLabel}}</label>
                <input class="form-control" type="date" id="fromDate" name="fromDate" [disabled] = "resourceForm.storeCode==='' || isViewMode" class="form-control" [(ngModel)]="resourceForm.fromDate" (click)="validateDLSChanges()"  (change)="onDateChangeFrom()" [min]="minDate" [max]="maxDate"/>
              </div>
              <div class="form-inline">
                <label for="toDate" class="col-form-label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{toLabel ? ('TO_LABEL' | translate) : toLabel}}</label>
                <input class="form-control" type="date" id="toDate" name="toDate" [disabled] = "resourceForm.fromDate==='' || resourceForm.storeCode==='' || isViewMode " class="form-control"  [(ngModel)]="resourceForm.toDate" (click)="validateDLSChanges()" (change)="onDateChangeTo()" [min]="minDate" [max]="maxDate" />              </div>
          </div>
          <span class="underline"></span>
          <div class="form-inline">
            <div class="text-info" for="dslInfo"></div>
            <div class="text-error" for="fromDate"></div>
            <div class="text-error" style="margin-left: 26%;" for="toDate"></div>
          </div>
          </div>
        </div>
      </div>

      <div *ngIf="!resourceForm.scheduleType && !(isViewMode )" class="form-group">
        <div class="row">
          <label class="control-label col-md-2"></label>
          <div class="col-md-6">
            <div class="row row-margin-left-zero">
              <label class="control-label mb-0 pb-0 pl-0 font-weight-bold">Time OFF?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
              <input type="checkbox" id="timeOff" name="timeOff" [disabled] = "isViewMode" [(ngModel)]="resourceForm.timeOff" (change)="changeTimeOff()" unchecked>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!resourceForm.scheduleType && resourceForm.timeOff" class="form-group">
        <div class="row">
          <label class="control-label col-md-2"></label>
          <div class="col-md-6">
            <div class="row row-margin-left-zero">
              <button type="button" (click)="addTimeOff()" class="btn btn-danger btn-sm" [disabled] = "resourceForm.fromDate==='' || resourceForm.toDate==='' || tempTimeOffSlots || isViewMode">Add Time Off</button>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group" id="add-time-off-0" name="add-time-off">
        <div class="row row-margin-left-zero">
          <label class="control-label col-md-2"></label>
          <div class="col-md-6">
            <div class="row">
              <div class="w-40">
                <label name="lbl-days-add-time-off" class="control-label pl-0 font-weight-bold">0. {{dateLabel ? ('DATE_LABEL' | translate) : dateLabel}}</label>
                <div class="form-inline">
                  <div class="form-inline">
                    <label class="col-form-label">{{fromLabel ? ('FROM_LABEL' | translate) : fromLabel}}</label>
                    <input type="date" id="from-date-add-time-off-0" name="days" class="form-control-sm ml-2 bg-light" [min]="resourceForm.today"/>
                    <textarea id="scheduleId-0" name="scheduleId" style="display:none;"></textarea>
                  </div>
                  <div class="form-inline">
                    <label class="col-form-label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{toLabel ? ('TO_LABEL' | translate) : toLabel}}</label>
                    <input type="date" id="to-date-add-time-off-0" name="days" class="form-control-sm ml-2 bg-light" [min]="resourceForm.today"/>
                  </div>
                </div>
              </div>
              <div class="w-60">
                <label class="control-label pl-5 font-weight-bold">{{timeLabel ? ('TIME_LABEL' | translate) : timeLabel}}</label>
                <div class="form-inline pl-5">
                  <div class="form-inline">
                    <label class="col-form-label">{{fromLabel ? ('FROM_LABEL' | translate) : fromLabel}}</label>
                    <input id="hour-from-add-time-off-0" name="hours" class="form-control-sm ml-2" type="number">
                    <select id="from-minute-add-time-off-0" name="minutes" class="form-control-sm ml-2 bg-light">
                      <option value=0>00</option>
                      <option value=15>15</option>
                      <option value=30>30</option>
                      <option value=45>45</option>
                    </select>
                  </div>
                  <div class="form-inline">
                    <label class="col-form-label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{toLabel ? ('TO_LABEL' | translate) : toLabel}}</label>
                    <input id="hour-to-add-time-off-0" name="hours" class="form-control-sm ml-2" type="number">
                    <select id="to-minute-add-time-off-0" name="minutes" class="form-control-sm ml-2 bg-light">
                      <option value=0>00</option>
                      <option value=15 selected="selected">15</option>
                      <option value=30>30</option>
                      <option value=45>45</option>
                    </select>
                  </div>
                  <div class="form-inline pl-4">
                    <button type="button" id="add-button-add-time-off-0" class="btn btn-link">Add</button>
                    <button type="button" id="edit-button-add-time-off-0" class="btn btn-link">Edit</button>
                    <button type="button" id="remove-button-add-time-off-0" class="btn btn-link">Remove</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <span class="underline"></span>
              <div id="error-of-add-time-off-0" class="text-error" for="add-time-off-0"></div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="timeSlots.length">
        <div class="table-details"><br /></div>
        <!-- <div class="row">
          <div class="form-inline col-md-8 pr-0 flex-row-reverse">
            <div *ngIf="resourceForm.scheduleType && !isLoading && items?.length" class="pagination">
              <pagination-controls (pageChange)="generateWeekView($event)" id="item-server"></pagination-controls>
            </div>
          </div>
        </div> -->
          
        

        <div class="row">
          <div class="col-md-8 pr-0">
            <div class="text-error" for="calendar"></div>
            <full-calendar  id="calendar" #calendar  [options]="calendarOptions">  </full-calendar>
            <!-- <table class="table table-hover table-sm">
              <thead class="thead-dark">
                <tr>
                  <th class="col-md-1">{{timeSlotLabel ? ('TIME_SLOT_LABEL' | translate) : timeSlotLabel}}</th>
                  <th class="col-md-1">{{mondayLabel}}</th>
                  <th class="col-md-1">{{tuesdayLabel}}</th>
                  <th class="col-md-1">{{wednesdayLabel}}</th>
                  <th class="col-md-1">{{thursdayLabel}}</th>
                  <th class="col-md-1">{{fridayLabel}}</th>
                  <th class="col-md-1">{{staturdayLabel}}</th>
                  <th class="col-md-1">{{sundayLabel}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of items | paginate: { currentPage: currentPage, itemsPerPage: limit, totalItems: totalItems, id: 'item-server' }">
                  <td class="nacell">{{item.timeSlot}}</td>
                  <td (hover)="onClickTimeSlot(item, 'monday')" [ngStyle]="{'background-color': (item.monday == 1) ? 'greenyellow' : (item.monday == 0) ? 'silver' : 'gray', 'cursor': (item.monday == 1 || item.monday == 0) ? 'pointer' : 'default'}"></td>
                  <td (click)="onClickTimeSlot(item, 'tuesday')" [ngStyle]="{'background-color': (item.tuesday == 1) ? 'greenyellow' : (item.tuesday == 0) ? 'silver' : 'gray', 'cursor': (item.tuesday == 1 || item.tuesday == 0) ? 'pointer' : 'default'}"></td>
                  <td (click)="onClickTimeSlot(item, 'wednesday')" [ngStyle]="{'background-color': (item.wednesday == 1) ? 'greenyellow' : (item.wednesday == 0) ? 'silver' : 'gray', 'cursor': (item.wednesday == 1 || item.wednesday == 0) ? 'pointer' : 'default'}"></td>
                  <td (click)="onClickTimeSlot(item, 'thursday')" [ngStyle]="{'background-color': (item.thursday == 1) ? 'greenyellow' : (item.thursday == 0) ? 'silver' : 'gray', 'cursor': (item.thursday == 1 || item.thursday == 0) ? 'pointer' : 'default'}"></td>
                  <td (click)="onClickTimeSlot(item, 'friday')" [ngStyle]="{'background-color': (item.friday == 1) ? 'greenyellow' : (item.friday == 0) ? 'silver' : 'gray', 'cursor': (item.friday == 1 || item.friday == 0) ? 'pointer' : 'default'}"></td>
                  <td (click)="onClickTimeSlot(item, 'saturday')" [ngStyle]="{'background-color': (item.saturday == 1) ? 'greenyellow' : (item.saturday == 0) ? 'silver' : 'gray', 'cursor': (item.saturday == 1 || item.saturday == 0) ? 'pointer' : 'default'}"></td>
                  <td (click)="onClickTimeSlot(item, 'sunday')" [ngStyle]="{'background-color': (item.sunday == 1) ? 'greenyellow' : (item.sunday == 0) ? 'silver' : 'gray', 'cursor': (item.sunday == 1 || item.sunday == 0) ? 'pointer' : 'default'}"></td>
                </tr>
              </tbody>
            </table> -->
          </div>
          <div class="col-md-3">
            <div class="row">
              <div class="note-box">
                <span class="border nacell"></span><label class="note-label">&nbsp;{{notAvailableLabel ? ('NOT_AVAILABLE_LABEL' | translate) : notAvailableLabel}}</label><br />
                <span class="border avcell"></span><label class="note-label">&nbsp;{{availableLabel ? ('AVAILABLE_LABEL' | translate) : availableLabel}}</label><br />
                <span class="border opcell"></span><label class="note-label">&nbsp;{{openLabel ? ('OPEN_LABEL' | translate) : openLabel}}</label><br />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<message-modal></message-modal>