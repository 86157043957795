import {environment} from 'src/environments/environment';

let apiUrl;
// apiUrl = "http://localhost:8080"
if (environment.nonLocal) {
  if (!location.origin) {
    apiUrl = location.protocol + '//'
      + location.hostname
      + (location.port ? ':' + location.port : '');
 }else{
    apiUrl = location.origin;
 }
} else {
  apiUrl = environment.apiUrl;
}

export const ENDPOINTS: any = {
  LOGIN: apiUrl + `/api/portal/authenticate`,
  OTP_LOGIN: apiUrl + `/api/portal/otpAuthenticate`,
  LOGIN_WITH_APP: apiUrl + `/api/login/authenticate/`,
  CHECK_STATUS: apiUrl + `/api/qr/status`,
  QR_VALIDATE: apiUrl + `/api/qr/validate`,
  OAUTH2_OKTA_AUTHENTICATE: apiUrl + `/api/portal/oAuth2Authenticate`,
  OAUTH2_AUTHENTICATE: apiUrl + `/oAuth2Authenticate`,
  OAUTH2_OKTA_LOGOUT: apiUrl + `/api/oAuth2Logout`,
  RECOVER_WEB_PASSWORD: apiUrl + `/api/recover_web_password`,
  RESET_WEB_PASSWORD: apiUrl + `/api/reset_web_password`,
  LOGOUT: apiUrl + `/api/portal/token/invalidate`,
  USER: apiUrl + `/api/portal/user`,
  USER_BY: apiUrl + `/api/portal/user/`,
  USER_FILTER: apiUrl + `/api/portal/user/filter`,
  ROLE: apiUrl + `/api/portal/role`,
  STORE: apiUrl + `/api/portal/store`,
  STORE_BY: apiUrl + `/api/portal/store/`,
  STORE_BY_COUNTRY: apiUrl + `/api/portal/store/country/`,
  STORE_FILTER: apiUrl + `/api/portal/store/filter`,
  TERMINAL: apiUrl + `/api/portal/terminal`,
  TERMINAL_BY: apiUrl + `/api/portal/terminal/`,
  TERMINAL_CODE: apiUrl + `/api/portal/terminal_code`,
  TERMINAL_FILTER: apiUrl + `/api/portal/terminal/filter`,
  TERMINAL_MODEL: apiUrl + `/api/portal/terminal_model`,
  TERMINAL_MODEL_BY: apiUrl + `/api/portal/terminal_model/`,
  COUNTRY: apiUrl + `/api/portal/country`,
  AUDIT: apiUrl + `/api/portal/audit_trail/`,
  AUDIT_TRAIL_FILTER: apiUrl + `/api/portal/audit_trail/filter`,
  ORDER_QUERY: apiUrl + `/api/portal/order/query/`,
  RESOURCE: apiUrl + `/api/portal/resource`,
  RESOURCE_BY: apiUrl + `/api/portal/resource/`,
  RESOURCE_SEARCH: apiUrl + `/api/portal/resource/search`,
  BOOKING_FILTER: apiUrl + `/api/portal/booking`,
  BOOKING_FETCH_RESOURCES: apiUrl + `/api/portal/booking/resources`,
  BOOKING_UPDATE_STATUS: apiUrl + `/api/portal/booking/status`,
  DOMO: apiUrl + `/api/portal/domoCard`,
  BOOKING_BY: apiUrl + `/api/ba/booking`,
}
