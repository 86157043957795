import {Component, OnInit} from '@angular/core';
import Bugsnag from '@bugsnag/js';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import * as moment from 'moment';
import {NotificationsService} from 'angular2-notifications';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import jsonToCsvExport from 'json-to-csv-export';
import { Router } from '@angular/router';

import {AuthService} from 'src/app/services/auth.service';
import {StoreService} from 'src/app/services/store.service';
import {ResourceService} from 'src/app/services/resource.service';
import {BookingService} from 'src/app/services/booking.service';

import {CONSTANTS} from 'src/app/common/constants';
import {Store} from 'src/app/models/store';

// Jquery
declare var $: any;

@Component({
  selector: 'booking-component',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})

export class BookingComponent implements OnInit {
  constructor(
    protected authService: AuthService,
    private storeService: StoreService,
    private resourceService: ResourceService,
    private bookingService: BookingService,
    public translate: TranslateService,
    private notificationsService: NotificationsService,
    private router: Router
  ) {}

  downloadLbl: string = CONSTANTS.DOWNLOAD;
  countryDefaultSelectOption: string = CONSTANTS.SELECT_COUNTRY;
  storeCountry: string = '';
  countries: string[] = [];
  storeDefaultSelectOption: string = CONSTANTS.SELECT_STORE;
  storeCode: string = '';
  stores: Store[] = [];
  bookings: any[] = [];

  dateLabel: string = CONSTANTS.DATE_LABEL;
  timeslotLabel: string = CONSTANTS.TIME_SLOT_LABEL;
  resourceLabel: string = CONSTANTS.RESOURCE_LABEL;
  ticketNoLabel: string = CONSTANTS.TICKET_NO_LABEL;
  clientNameLabel: string = CONSTANTS.CLIENT_NAME_LABEL;
  clientContactLabel: string = CONSTANTS.CLIENT_CONTACT_LABEL;
  activityTypeLabel: string = CONSTANTS.ACTIVITY_TYPE_LABEL;
  activityLabel: string = CONSTANTS.ACTIVITY_LABEL;
  statusLabel: string = CONSTANTS.STATUS_LABEL;
  action: string = CONSTANTS.ACTION;
  baAttended: string = CONSTANTS.BA_ATTENDED;
  baName: string = CONSTANTS.BA_NAME;

  showingBookingsFrom: string = CONSTANTS.SHOWING_BOOKINGS_FROM;
  ofTotal: string = CONSTANTS.OF_TOTAL;
  to: string = CONSTANTS.TO;
  bookingsMsg: string = CONSTANTS.BOOKINGS_MSG;
  noBookingFound: string = CONSTANTS.NO_BOOKING_FOUND;
  search: string = CONSTANTS.SEARCH_CLIENT;
  activityTypeFilterLbl: string = CONSTANTS.FILTER_BY_ACTIVITY_TYPE;
  activityFilterLbl: string = CONSTANTS.FILTER_BY_ACTIVITY;
  statusFilterLbl: string = CONSTANTS.FILTER_BY_STATUS;
  resourceFilterLbl: string = CONSTANTS.FILTER_BY_RESOURCE;

  currentPage: number = 1;
  beginRecord: number = 0;
  endRecord: number = 0;
  totalRecords: number = 0;
  limit: number = 50;

  activityTypes: any[] = [];
  activityIds: any[] = [];
  statusIds: any[] = [];
  resourceIds: any[] = [];

  multiselectAll: string = '';
  multisunelectAll: string = '';

  activityType: string = '';
  activityTypeSelectPlaceHolder: string = '';
  activityTypeList: any[] = [];

  ActivityMultiselectPlaceHolder: string = '';
  activityDropdownList: any[] = [];
  activityDropdownSettings: IDropdownSettings = {};
  selectedActivities: any[] = [];

  statusMultiselectPlaceHolder: string = '';
  statusDropdownList: any[] = []; 
  statusDropdownSettings: IDropdownSettings = {};
  selectedStatuses: any[] = [];

  resourceMultiselectPlaceHolder: string = '';
  resourceDropdownList: any[] = [];
  resourceDropdownSettings: IDropdownSettings = {};
  selectedResources: any[] = [];

  keywords = '';
  onSearch: boolean = false;
  isLoading: boolean = false;
  startDate: string = '';
  endDate: string = '';
  hasViewAccess: boolean = false;

  startBtn: string = CONSTANTS.START_BTN;
  checkInBtn: string = CONSTANTS.CHECK_IN_BTN;
  completeBtn: string = CONSTANTS.COMPLETE_BTN;
  revertBtn: string = CONSTANTS.REVERT_BTN;
  noShowBtn: string = CONSTANTS.NO_SHOW_BTN;
  cancelBtn: string = CONSTANTS.CANCEL;

  ngOnInit(): void {
    this.multiselectAll = this.translate.instant(CONSTANTS.SELECT_ALL);
    this.multisunelectAll = this.translate.instant(CONSTANTS.UNSELECT_ALL);
    this.activityTypeSelectPlaceHolder = this.translate.instant(CONSTANTS.SELECT_ACTIVITY_TYPE);
    this.ActivityMultiselectPlaceHolder = this.translate.instant(CONSTANTS.SELECT_OPTIONS);
    this.translate.stream('SELECT_ALL').subscribe((selectAllLabel: string) => {
      this.activityDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'activityName',
        selectAllText: selectAllLabel,
        unSelectAllText: this.translate.instant('DESELECT_ALL'),
        searchPlaceholderText: this.translate.instant('SEARCH'),
        noDataAvailablePlaceholderText: this.translate.instant('NO_DATA_AVAILABLE'),
        allowSearchFilter: true
      };
    });
    
    this.statusMultiselectPlaceHolder = this.translate.instant(CONSTANTS.SELECT_OPTIONS);
    this.translate.stream('SELECT_ALL').subscribe((selectAllLabel: string) => {
      this.statusDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'status',
        selectAllText: selectAllLabel,
        unSelectAllText: this.translate.instant('DESELECT_ALL'),
        searchPlaceholderText: this.translate.instant('SEARCH'),
        noDataAvailablePlaceholderText: this.translate.instant('NO_DATA_AVAILABLE')
      };
    });
    
    this.resourceMultiselectPlaceHolder = this.translate.instant(CONSTANTS.SELECT_OPTION);
    this.translate.stream('SELECT_ALL').subscribe((selectAllLabel: string) => {
      this.resourceDropdownSettings = {
        singleSelection: true,
        idField: 'id',
        textField: 'resourceName',
        selectAllText: selectAllLabel,
        unSelectAllText: this.translate.instant('DESELECT_ALL'),
        searchPlaceholderText: this.translate.instant('SEARCH'),
        noDataAvailablePlaceholderText: this.translate.instant('NO_DATA_AVAILABLE'),
        allowSearchFilter: true
      };
    });

    this.hasViewAccess = this.authService.isSM || this.authService.isSMT || this.authService.isBM || this.authService.isBR;
    this.storeCountry = (this.authService.isSystemAdmin || this.authService.isRetailOps) ? this.authService.location : this.authService.storeCountry;
    this.storeCode = !(this.authService.isSM || this.authService.isSMT) ? '' : this.authService.storeCode;
    this.countries = this.authService.isSystemAdmin ? this.authService.countries : [this.storeCountry];
    this.storeService.getStoresByCountry(this.storeCountry).subscribe(res => {
      this.stores = res;
      if (this.storeCode && this.stores.filter((it: any) => [this.storeCode].includes(it.storeCode as string)).length > 0) {
        this.initDateRange();
        this.fetchStoreProperties(this.storeCode);
      }
    });
  }

  private fetchStoreProperties(storeCode: string) {
    this.translate.stream('SERVICES').subscribe((services: string) => {
      this.activityTypeList = [
        { id: 'Service', activityTypeName: services },
        { id: 'Event', activityTypeName: this.translate.instant('EVENTS') },
        { id: 'Class', activityTypeName: this.translate.instant('CLASSES') },
      ];
    });
    
    this.translate.stream('UPCOMING').subscribe((upcoming: string) => {
      this.statusDropdownList = [
        { id: 'booked', status: upcoming },
        { id: 'checked-in', status: this.translate.instant('CHECKED_IN') },
        { id: 'no-show', status: this.translate.instant('NO_SHOW') },
        { id: 'in-progress', status: this.translate.instant('IN_PROGRESS') },
        { id: 'cancelled', status: this.translate.instant('CANCELLED') },
        { id: 'completed', status: this.translate.instant('COMPLETED') },
      ];
    });
    this.isLoading = true;
    if (storeCode === localStorage.getItem('onSelectBookingStoreCode') as string) {
      this.resourceDropdownList = JSON.parse(localStorage.getItem('bookingResourceDropdownList') as string);
      this.activityDropdownList = JSON.parse(localStorage.getItem('bookingActivityDropdownList') as string);
      this.getBookingList(this.currentPage);
    } else {
      forkJoin(
        this.bookingService.fetchResources(storeCode),
        this.resourceService.fetchActivityNames(storeCode, [])
      ).subscribe(
        res => {
          if (res[0].resources && res[0].resources.length) {
            this.resourceDropdownList = res[0].resources;
            localStorage.setItem('bookingResourceDropdownList', JSON.stringify(this.resourceDropdownList));
          }
          if (res[1].body.activityNames && res[1].body.activityNames.length) {
            this.activityDropdownList = res[1].body.activityNames;
            localStorage.setItem('bookingActivityDropdownList', JSON.stringify(this.activityDropdownList));
          }
          if (res[0].resources.length && res[1].body.activityNames.length) {
            localStorage.setItem('onSelectBookingStoreCode', storeCode);
            this.getBookingList(this.currentPage);
          }
          this.isLoading = false;
        },
        err => {
          this.storeCode = '';
          this.bookings = [];
          this.notificationsService.error(
            CONSTANTS.ERROR,
            CONSTANTS.BOOKING_FETCH_STORE_FAIL,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
           }
          );
          Bugsnag.notify(err);
          this.isLoading = false;
        }
      );
    }
  }

  navigateToBookingDetails(storeCode: string, confirmationNumber: string): void {
      this.router.navigate([`/booking/view/${storeCode}/${confirmationNumber}`]);
  }

  onSelectCountry(event: any) {
    this.storeCountry = (event.target as HTMLInputElement).value as string;
    this.storeCode = '';
    if (this.storeCountry && this.countries.includes(this.storeCountry)) {
      this.storeService.getStoresByCountry(this.storeCountry).subscribe(res => {
        this.stores = res;
        this.bookings = [];
      });
      this.clearError('storeCountry');
    } else {
      this.bookings = [];
      this.showError('storeCountry', CONSTANTS.SELECT_COUNTRY);
    }
  }

  onSelectStore(event: any) {
    this.storeCode = (event.target as HTMLInputElement).value as string;
    if (this.storeCode && this.stores.filter((it: any) => [this.storeCode].includes(it.storeCode as string)).length > 0) {
      this.initDateRange();
      this.clearError('storeCode');
      this.fetchStoreProperties(this.storeCode);
    } else {
      this.storeCode = '';
      this.bookings = [];
      this.showError('storeCode', CONSTANTS.SELECT_STORE);
    }
  }

  onSelectActivityType(event: any) {
    const activityTypeId = ((event.target as HTMLInputElement).value as string);
    if (activityTypeId) {
      this.activityDropdownList = this.activityDropdownList.filter((a: any) =>  a.activityTypeId == activityTypeId);
    } else {
      this.activityDropdownList = JSON.parse(localStorage.getItem('bookingActivityDropdownList') as string);
    }
    this.getBookingList(1);
  }

  private initDateRange() {
    if (!this.startDate && !this.endDate) {
      const tempDate = new Date();
      this.startDate = moment(tempDate).format('YYYY-MM-DD');
      this.endDate = moment(tempDate.setDate((new Date()).getDate() + 30)).format('YYYY-MM-DD');
    }
  }

  onChangeStartDate() {
    if (this.startDate && new Date(this.startDate)) {
      if (this.endDate) {
        if (new Date(this.endDate)) {
          this.validateDateRange(new Date(this.startDate), new Date(this.endDate));
        }
      } else {
        this.showError('endDate', CONSTANTS.ERROR_REQUIRE_TEXT);
      }
    } else {
      this.clearError('endDate');
      this.endDate = '';
      this.getBookingList(1);
    }
  }

  onChangeEndDate() {
    if (this.endDate && new Date(this.endDate)) {
      if (this.startDate) {
        if (new Date(this.startDate)) {
          this.validateDateRange(new Date(this.startDate), new Date(this.endDate));
        }
      } else {
        this.showError('startDate', CONSTANTS.ERROR_REQUIRE_TEXT);
      }
    } else {
      this.startDate = '';
      this.getBookingList(1);
    }
  }

  validateDateRange(startDate: any, endDate: any) {
    if (startDate.getTime() > endDate.getTime()) {
      this.showError('endDate', CONSTANTS.ERROR_DATE_RANGE);
    } else if (moment(Date.parse(this.endDate)).diff(moment(Date.parse(this.startDate)), 'days') > 91) {
      this.showError('endDate', CONSTANTS.ERROR_DATE_RANGE_MORE_THAN_3_MONTHS);
    } else {
      this.clearError('endDate');
      this.getBookingList(1);
    }
  }

  getBookingList(page: number) {
    this.isLoading = true;
    if (this.storeCode) {
      this.bookingService.filter(this.storeCode, false, page, this.keywords, this.startDate, this.endDate, this.activityType, this.activityIds, this.resourceIds, this.statusIds).subscribe(
        res => {
          this.bookings = res.items;
          this.totalRecords = res.total;
          this.currentPage = page;
          this.beginRecord = (page - 1) * this.limit + 1;
          if (res.total == 0) this.beginRecord = 0;
          this.endRecord = this.beginRecord + this.limit - 1;
          if (this.endRecord > this.totalRecords) this.endRecord = this.totalRecords;
          
          this.bookings.forEach((i: any) => {
            if (i.resource) {
              const resource = this.resourceDropdownList.find((r: any) => r.id = i.resource);
              i.resource = resource ? resource.resourceName : '';
            }
            this.convertStatusToShowingBAMUI(i);
          });
          this.isLoading = false;
        },
        err => {
          this.bookings = [];
          this.currentPage = 1;
          this.beginRecord = 0;
          this.endRecord = 0;
          this.totalRecords = 0;
          Bugsnag.notify(err);
          this.notificationsService.error(
            CONSTANTS.ERROR,
            CONSTANTS.BOOKING_FILTER_FAIL,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
           }
          );
          this.isLoading = false;
        }
      );
    }
  }

  private convertStatusToShowingBAMUI(i: any) {
    if (i.status) {
      switch (i.status) {
        case 'BOOKED': {
          i.bamStatusText = 'Upcoming';
          i.checkinable = true;
          i.noshowable = true;
          i.cancelable = true;
          break;
        }
        case 'CHECKED_IN': {
          i.bamStatusText = 'Checked in';
          i.startable = true;
          i.revertable = true;
          i.cancelable = true;
          break;
        }
        case 'NO_SHOW_MANUAL_UPDATE': case 'NO_SHOW_SYSTEM_UPDATE': {
          i.bamStatusText = 'No Show';
          i.revertable = true;
          break;
        }
        case 'IN_PROGRESS': {
          i.bamStatusText = 'In Progress';
          i.completable = true;
          i.revertable = true;
          i.cancelable = true;
          break;
        }
        case 'CUSTOMER_CANCELLED': case 'SEPHORA_CANCELLED': {
          i.bamStatusText = 'Cancelled';
          i.cancelled = true;
          i.lastModifiedText = i.lastModifiedBy ? (this.translate.instant(CONSTANTS.CANCELLED_BY_TXT) + i.lastModifiedBy + this.translate.instant(CONSTANTS.ON_TXT) + i.lastModifiedDate) : this.translate.instant(CONSTANTS.CANCELLED_TXT) + this.translate.instant(CONSTANTS.AT_TXT) + i.lastModifiedDate;
          break;
        }
        case 'COMPLETED': case 'SYSTEM_COMPLETED': {
          i.bamStatusText = 'Completed';
          i.completed = true;
          i.lastModifiedText = i.lastModifiedBy ? (this.translate.instant(CONSTANTS.COMPLETED_BY_TXT) + i.lastModifiedBy + this.translate.instant(CONSTANTS.ON_TXT) + i.lastModifiedDate) : this.translate.instant(CONSTANTS.COMPLETED_TXT) + this.translate.instant(CONSTANTS.AT_TXT) + i.lastModifiedDate;
          break;
        }
        default:
          i.bamStatus = 'N/A';
          break;
      }
    }
  }

  download() {
    this.isLoading = true;
    if (this.storeCode) {
      let downloadBookingList: any[] = [];
      this.bookingService.filter(this.storeCode, true, 0, this.keywords, this.startDate, this.endDate, this.activityType, this.activityIds, this.resourceIds, this.statusIds).subscribe(
        res => {
          res.items.forEach((i: any) => {
            if (i.resource) {
              const resource = this.resourceDropdownList.find((r: any) => r.id = i.resource);
              i.resource = resource ? resource.resourceName : '';
            }
            switch (i.status) {
              case 'BOOKED': {
                i.bamStatusText = 'Upcoming';
                i.nextActions = 'Check in, No Show, Cancel';
                break;
              }
              case 'CHECKED_IN': {
                i.bamStatusText = 'Checked in';
                i.nextActions = 'Start, Revert, Cancel';
                break;
              }
              case 'NO_SHOW_MANUAL_UPDATE': case 'NO_SHOW_SYSTEM_UPDATE': {
                i.bamStatusText = 'No Show';
                i.nextActions = 'Revert';
                break;
              }
              case 'IN_PROGRESS': {
                i.bamStatusText = 'In Progress';
                i.nextActions = 'Complete, Revert, Cancel';
                break;
              }
              case 'CUSTOMER_CANCELLED': case 'SEPHORA_CANCELLED': {
                i.bamStatusText = 'Cancelled';
                i.lastModifiedText = i.lastModifiedBy ? (this.translate.instant(CONSTANTS.CANCELLED_BY_TXT) + i.lastModifiedBy + this.translate.instant(CONSTANTS.ON_TXT) + i.lastModifiedDate) : this.translate.instant(CONSTANTS.CANCELLED_TXT) + this.translate.instant(CONSTANTS.AT_TXT) + i.lastModifiedDate;
                break;
              }
              case 'COMPLETED': case 'SYSTEM_COMPLETED': {
                i.bamStatusText = 'Completed';
                i.lastModifiedText = i.lastModifiedBy ? (this.translate.instant(CONSTANTS.COMPLETED_BY_TXT) + i.lastModifiedBy + this.translate.instant(CONSTANTS.ON_TXT) + i.lastModifiedDate) : this.translate.instant(CONSTANTS.COMPLETED_TXT) + this.translate.instant(CONSTANTS.AT_TXT) + i.lastModifiedDate;
                break;
              }
              default:
                i.bamStatus = '';
                i.nextActions = '';
                break;
            }
            downloadBookingList.push(
              {
                Date: i.dateSlot ? i.dateSlot : '',
                Time_Slot: i.timeSlot ? i.timeSlot: '',
                Resource: i.resourceName ? i.resourceName: '',
                Ticket_No: i.confirmationNumber ? i.confirmationNumber: '',
                Client_Name: i.clientName ? i.clientName: '',
                Client_Contact: i.clientContact ? i.clientContact: '',
                Activity_Type: i.activityType ? i.activityType: '',
                Activity: i.activityName ? i.activityName: '',
                //BA_Attended: i.baAttended ? i.baAttended: '',
                //BA_Name: i.baName ? i.baName: '',
                Status: i.bamStatusText ? i.bamStatusText: '',
                Action: i.lastModifiedText ? i.lastModifiedText: i.nextActions
              }
            );
          });
          const dataToConvert = {
            data: downloadBookingList,
            filename: 'Activity_Bookings',
            delimiter: ','
          }
          jsonToCsvExport(dataToConvert);
          this.isLoading = false;
        },
        err => {
          Bugsnag.notify(err);
          this.notificationsService.error(
            CONSTANTS.ERROR,
            CONSTANTS.BOOKING_FILTER_FAIL,
            {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
              clickToClose: true
           }
          );
          this.isLoading = false;
        }
      );
    }
  }

  onSearchInput() {
    this.onSearch = this.keywords != ''
    this.getBookingList(1);
  }

  clearSearch() {
    this.keywords = '';
    this.onSearch = this.keywords != ''
    this.getBookingList(1);
  }

  clearErrorIfValidRequire(target: any) {
    let content = target.value;
    if (content && content.trim()) {
      this.clearError(target.name);
    }
  }

  private clearError(inputId: string) {
    $("div.text-error[for='" + inputId + "']").hide();
  }

  private showError(inputId: string, message: string) {
    $("div.text-error[for='" + inputId + "']").html(message).show();
  }

  updateStatus(confirmationNumber: string, status: string, eNewStatus: string) {
    this.isLoading = true;
    this.bookingService.updateStatus(confirmationNumber, status, this.storeCode).subscribe(
      res => {
        this.bookings.forEach((i: any) => {
          if (i.confirmationNumber === confirmationNumber) {
            i.startable = false;
            i.checkinable = false;
            i.completable = false;
            i.revertable = false;
            i.noshowable = false;
            i.cancelable = false;
            i.status = eNewStatus;
            i.lastModifiedBy = this.authService.userId;
            this.convertStatusToShowingBAMUI(i);
          }
        });
        this.notificationsService.success(
          CONSTANTS.SUCCESS,
          CONSTANTS.SUCCESS_MSG,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isLoading = false;
      },
      err => {
        this.notificationsService.error(
          CONSTANTS.ERROR,
          CONSTANTS.BOOKING_UPDATE_STATUS_FAIL,
          {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true
         }
        );
        this.isLoading = false;
        Bugsnag.notify(err);
      }
    );
  }

  onActivitySelect(item: any) {
    this.activityIds.push(item.id);
    this.getBookingList(1);
  }

  onActivityDeSelect(item: any) {
    this.activityIds = this.activityIds.filter(i => i != item.id);
    this.getBookingList(1);
  }

  onActivitySelectAll(items: any) {
    this.activityIds = [];
    items.forEach((i:any) => this.activityIds.push(i.id));
    this.getBookingList(1);
  }

  onActivityDeSelectAll() {
    this.activityIds = [];
    this.getBookingList(1);
  }

  onStatusSelect(item: any) {
    this.statusIds.push(item.id);
    this.getBookingList(1);
  }

  onStatusDeSelect(item: any) {
    this.statusIds = this.statusIds.filter(i => i != item.id);
    this.getBookingList(1);
  }

  onStatusSelectAll(items: any) {
    this.statusIds = [];
    items.forEach((i:any) => this.statusIds.push(i.id));
    this.getBookingList(1);
  }

  onStatusDeSelectAll() {
    this.statusIds = [];
    this.getBookingList(1);
  }

  onResourceSelect(item: any) {
    this.resourceIds = [];
    this.resourceIds.push(item.id);
    this.getBookingList(1);
  }

  onResourceDeSelect() {
    this.resourceIds = [];
    this.getBookingList(1);
  }

}