
    <div id="qrModal" class="modal">
        <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title">Scan QR Code to Login with BA App</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" (click)="closeModal()">&times;</span>
            </button>
            </div>
            <div class="modal-body col-md-16">
                <div class = "centered">
                    <a [href]="sanitize(baappurl)" target="_blank">
                        <img id="qrImage" src="" alt="QR Code">
                    </a>
                </div>
            </div>
        </div>
    </div>
    </div>
