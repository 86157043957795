import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {NotificationsService} from 'angular2-notifications';

import {AuthService} from 'src/app/services/auth.service';
import {UserService} from 'src/app/services/user.service';
import {PasswordValiationMessage} from 'src/app/models/password-validation-message';
import { CONSTANTS } from 'src/app/common/constants';
import { TranslateService } from '@ngx-translate/core';

// Jquery
declare var $: any;

@Component({
  selector: 'changewebpass.component',
  templateUrl: './changewebpass.component.html',
  styleUrls: ['./changewebpass.component.css']
})
export class ChangeWebPassComponent implements OnInit {
  $ = $;
  userId: string = '';
  password: string = '';
  passwordConfirm: string = '';
  isFormValid: boolean = false;
  isLoading: boolean = false;
  baLoginId=localStorage.getItem('userId');
  passwordValidationMessages: Array<PasswordValiationMessage> = [];
  showMessages_newPassword = false;
  changeWebPassTitle: string = CONSTANTS.CHANGE_WEB_PASSWORD_TITLE;
  employeeId: string = CONSTANTS.EMPLOYEE_ID;
  newPassword: string = CONSTANTS.NEW_PASSWORD;
  passwordConfirmation: string = CONSTANTS.PASSWORD_CONFIRMATION;
  passwordValidation: string = CONSTANTS.PASSWORD_NOT_MATCH;
  changePassword: string = CONSTANTS.CHANGE_PASSWORD;

  constructor(
    private title: Title,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private notificationsService: NotificationsService,
    public translate: TranslateService
    ) {
    this.translate.addLangs(['en', 'th', 'my', 'cn', 'kr']);
    // Set default language
    this.translate.setDefaultLang('en-SG');
  }

  ngOnInit() {
    this.title.setTitle(CONSTANTS.CHANGE_PASSWORD);
    this.userId = localStorage.getItem('id')!;
  }

  handleResetPassword() {
    this.isFormValid = true;
    this.clearError();
    this.validateRequired();
    this.compareConfirmPassword('password-confirm');
    if (!this.isFormValid) return;
    this.isLoading = true;
    this.resetPassword();
  }

  compareConfirmPassword(inputName: string) {
    let content = $("[name='" + inputName + "']").val();
    this._clearErrorPw(inputName);

    if (!content || !content.trim()) {
      this.isFormValid = false;
      return;
    }
    if (this.password !== content) {
      this.isFormValid = false;
      this._showErrorPw(inputName);
    }
  }

  resetPassword() {
    let baLoginId = localStorage.getItem('userId');
    let fullName = localStorage.getItem('fullName');
    let newPassword = $('[name=password]').val();
    // change password
    let data = {
      newPassword,
      fullName,
      baLoginId,
      userId: this.userId
    }
    this.authService.changeWebPortalPassword(this.userId, data).subscribe(
      res => {
        this.router.navigate(['/login']);
      },
      err => {
        this.isLoading = false;
        if(err.status === 403){
          this.showErrorPopup(CONSTANTS.NEW_PASSWORD_OLD_PASSWORD_SAME)
        }else{
          this.showErrorPopup(CONSTANTS.CHANGE_PASSWORD_USER + this.userId + CONSTANTS.CHANGE_PASSWORD_FAILED)
      }
      }
    );
  }

  clearErrorIfValid(e: any) {
    if (e.target.value) {
      $(e.target)
        .siblings('.text-error')
        .eq(0)
        .css('opacity', 0);
    }
  }

  clearError() {
    $('.text-error').css('opacity', 0);
  }

  disableButtons() {
    $('button').addClass('disabled');
    $('button').attr('disabled', true);
  }

  enableButtons() {
    $('button').removeClass('disabled');
    $('button').attr('disabled', false);
  }

  validateRequired() {
    if (!this.password || !this.password.trim()) {
      $('#password ~ .text-error')
        .eq(0)
        .css('opacity', 1);
      this.isFormValid = false;
    }
    if (!this.passwordConfirm || !this.passwordConfirm.trim()) {
      $('#password-confirm ~ .text-error')
        .eq(0)
        .css('opacity', 1);
      this.isFormValid = false;
    }
  }

  ecomValidatePassword() {
    if(this.password.length > 0){
        this.clearError();
        this.userService.ecomValidatePassword(this.password, this.passwordConfirm).subscribe(
            res => {
                this.passwordValidationMessages = [].concat(res.body.data.attributes.password_validations);
           },
            err => {
           }
        );
        this.showMessages_newPassword = true;
   } else {
        this.showMessages_newPassword = false;
   }
  }
  // Show password error message under input
  private _showErrorPw(inputName: string) {
    let inputElement = $("[name ='" + inputName + "']");
    inputElement.focus();
    inputElement.addClass('input-error');
    $("div.text-error-pw[for='" + inputName + "']").show();
  }

  //Clear password error message under input
  private _clearErrorPw(inputName: string) {
    let inputElement = $("[name ='" + inputName + "']");
    inputElement.removeClass('input-error');
    $("div.text-error-pw[for='" + inputName + "']").hide();
  }

  handleKeyDown(event: any) {
    if (this.isLoading) return;
    // If 'Enter'
    if (event.keyCode == 13) {
       this.handleResetPassword();
    }
  }
  showErrorPopup(message: string) {
    this.notificationsService.error(
      CONSTANTS.ERROR,
      message,
      {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true
      }
    );
  }
}
