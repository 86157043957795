import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css']
})
export class BookingDetailsComponent implements OnInit {
  bookingData: any = null;
  storeCode: string = '';
  confirmationNumber: string = '';
  isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.storeCode = this.route.snapshot.paramMap.get('storeCode') || '';
    this.confirmationNumber = this.route.snapshot.paramMap.get('confirmationNumber') || '';

    if (this.storeCode && this.confirmationNumber) {
      this.fetchBookingDetails();
    }
  }

  fetchBookingDetails(): void {
    this.bookingService.getOneBooking(this.storeCode, this.confirmationNumber)
      .subscribe(
        (response: any) => {
          if (response?.result) {
            this.bookingData = response.result;
            this.isLoading = false;
          }
        },
        (error) => {
          console.error('Error fetching booking details:', error);
        }
      );
  }
  formatClientQuestionsWithConsent(questionsAnswers: any[]): string {
    if (!questionsAnswers || questionsAnswers.length === 0) {
      return "No client input available.";
    }
    
    return questionsAnswers
      .map(q => `Q: ${q.question}\nA: ${q.answer || "No answer provided"}\nConsent: ${q.isConsent ? 'Yes' : 'No'}\n`)
      .join("\n");
  }

  cancelForm() {
    this.backToBookingList();
  }

  backToBookingList() {
    this.router.navigate(['/management'], {queryParams: {t: 'activity'}});
  }

  
}
