import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';

import {AuthService} from 'src/app/services/auth.service';
import {UtilService} from 'src/app/services/util.service';
import {ENDPOINTS} from 'src/app/common/endpoints';

@Injectable()
export class ResourceService {
  constructor(private http: HttpClient, private utilService: UtilService, protected authService: AuthService) {}

  create(data: any): Observable<any> {
    return this.http
      .put(ENDPOINTS.RESOURCE, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
  }

  update(data: any): Observable<any> {
    return this.http
      .post(ENDPOINTS.RESOURCE, data, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
  }

  updateStatus(storeCode: string, id: number, status: number): Observable<any> {
    return this.http
      .post(ENDPOINTS.RESOURCE_BY + storeCode + "/" + id + "/" + status, {}, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
  }

  delete(storeCode: string, id: number): Observable<any> {
    return this.http
      .delete(ENDPOINTS.RESOURCE_BY + storeCode + "/" + id, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.dataSuccess),
      catchError(this.utilService.handleError));
  }

  search(
    page: number,
    limit: number,
    storeCode: string,
    keywords: string,
    expertiseIds: any[],
    activityGroupIds: any[],
    activityNameIds: any[]
  ): Observable<any> {
    let conditions = {
      offset: (page - 1) * limit,
      limit,
      storeCode,
      keywords,
      expertiseIds,
      activityGroupIds,
      activityNameIds
    };
    return this.http
      .post(ENDPOINTS.RESOURCE_SEARCH, conditions, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
  }

  getResourceById(id: number): Observable<any> {
    return this.http
      .get(ENDPOINTS.RESOURCE_BY + id, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
  }

  getScheduleItemPage(id: number, startDate: string, scheduleType: number): Observable<any> {
    return this.http
      .get(ENDPOINTS.RESOURCE_BY + id + `/${scheduleType}`, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token, 'offsetDate': startDate}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
  }

  fetchExpertise(): Observable<any> {
    return this.http
      .get(ENDPOINTS.RESOURCE_BY + "expertise", {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
  }

  fetchActivityGroups(location: string, storeCode: string): Observable<any> {
    return this.http
      .get(ENDPOINTS.RESOURCE_BY + "activity_groups?" + (storeCode ? ("storeCode=" + storeCode) : ("location=" + location)), {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
  }

  fetchActivityNames(storeCode: string, activityGroupIds: any[]): Observable<any> {
    return this.http
      .get(ENDPOINTS.RESOURCE_BY + "activity_names/" + storeCode + (!activityGroupIds ? "" : `?activityGroupIds=${activityGroupIds}`), {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
  }

  fetchStoreTimes(storeCode: string): Observable<any> {
    return this.http
      .get(ENDPOINTS.STORE_BY + "time/" + storeCode, {headers:{'Content-Type': 'application/json', 'Token': this.authService.token}, observe: 'response', responseType: 'json'})
      .pipe(map(this.utilService.extractData),
      catchError(this.utilService.handleError));
  }
}